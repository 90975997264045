Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "bx_block_tasks/tickets/";
exports.allTaskListAPIEndPoint = "bx_block_tasks/tasks/get_all_tasks"
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.getAllAgentsApiMethod = 'GET'
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";

exports.cabLabel = 'Change Advisory Board (CAB)';
exports.cabGroupLabel = 'CAB groups';
exports.exportBTN = 'Export';
exports.addNewCabLabel = 'Add new CAB'
exports.cabNameLabel = 'CAB Name';
exports.descriptionLabel = "Description";
exports.cabNamePlaceholder = 'Enter CAB Name'
exports.cabDescriptionPlaceholder = 'Enter description'
exports.textCancel = 'Delete'
exports.textSaveChanges = 'Save changes'
exports.textAddMember = 'Add member'
exports.textMember = 'Member'
exports.textMembers = 'Members'
exports.textMembers2 = 'members'
exports.textAddMembers = 'Add members'
exports.textEditDetails = 'Edit details'
exports.textDetails = 'Details'
exports.textSelectMember = 'Select Member'
exports.textNoData = 'No Data.'
exports.textSelectMembers = 'Please select a member before adding a new one.'
exports.textCabUpdate = "Changes have been applied."
exports.textNoMember = 'There are no member added.'
exports.textSelectMemberMessage = 'Please select any member.'
exports.textSearch = 'Search...'
exports.textUpdateMessage = 'New members have been added.'

exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.getAllCabsApiEndPoint = "bx_block_tasks/cabs"
exports.createCabApiEndPoint = "bx_block_tasks/cabs"
exports.getCabApiEndPoint = "bx_block_tasks/cabs/"
exports.updateCabDetailsApiEndPoint = "bx_block_tasks/cabs/"
exports.updateMemberApiEndPoint = "bx_block_tasks/cab_groups/"
exports.updateMemberApiEndPoint2 = '/update_member'
exports.settingLabel = "Settings";
exports.userManagementLabel = "User management";
exports.agentGroupsLabel = "Agent groups";
exports.agentsLabel = "Agents";
exports.searchPlacholder = "Search...";
exports.addNewAgentGroup = "Add new agent group";
exports.addAgent = "Add agent";
exports.addGroupLabel = "Add group";
exports.cancelIconAltText = "Cancel...";
exports.details = "Details";
exports.generalLabel = "General";
exports.nameInputId = "name";
exports.groupNameLabel = "Group Name";
exports.businessHourInputId = "business_hours";
exports.businessHourLabel = "Business Hours";
exports.selectBusinessHourLabel = "Select Business Hours";
exports.defaultLabel = "Default";
exports.businessFunctionInputId = "business_function";
exports.businessFunctionLable = "Business Function";
exports.selectBusinessFunctionLabel = "Select Business Function";
exports.categoryLabel = "Category";
exports.categoryPlaceholder = "Select Category";
exports.descriptionInputId = "description";
exports.groupAutomationLabel = "Group Automation";
exports.detailAutomation = "Automations";
exports.automationTimeInputId = "automation_time";
exports.automationTimeLabel = "if a ticket remains un-assigned for more than";
exports.escalationEmailInputId = "escalation_email";
exports.escalationEmailLabel = "then send escalation email to";
exports.selectAgentLabel = "Select Agent";
exports.cancelBTN = 'Cancel';
exports.addBtn = "Add group";
exports.toasterMessage = "New group has been added.";
exports.agentGroupsApiContentType = "application/json";
exports.getAgentGroupApiMethod = "GET";
exports.getAgentGroupsApiEndPoint = "bx_block_department/groups";
exports.getAllAgentsApiMehotd = "GET";
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.searchGroupAPIendPoint = "/bx_block_department/groups/search_group";
exports.createAgentGroupApiMethod = "POST";
exports.createAgentGroupsApiEndPoint = "bx_block_department/groups";
exports.getParticularAgentGroupMethod = "GET";
exports.getParticularAgentGroupApiEndPoint = "bx_block_department/groups";
exports.updateAgentGroupsApiMethod = "PATCH";
exports.updateAgentGroupsApiEndPoint = "bx_block_department/groups";
exports.cabCreatedMessage = "New CAB has been added.";

exports.getAllTicketsApiEndPoint = "bx_block_custom_form/tickets";
exports.getRelatedTicketsEndPoint = "/bx_block_cfincidentmanagementticket/incident_ticket_managements/"
exports.getTicketDataApiEndPoint = 'bx_block_custom_form/tickets/';
exports.getParticularGroupAPIEndPoint = "bx_block_department/groups/";
exports.textGroupBy = 'Group By: '
exports.textNone = 'none'
exports.textAllTickets = 'All Tickets'
exports.textTickets = 'Tickets'
exports.textCreateNewTicket = 'Create new ticket'
exports.textTable = 'Table'
exports.textBorad = 'Board'
exports.textExport = 'Export'
exports.textFilter = 'Filter'
exports.textSort = 'Sort'
exports.textSubject = 'Subject'
exports.textRequester = 'Requester'
exports.textStatus = 'Status'
exports.textPriority = 'Priority'
exports.textAssignedTo = ' Assigned to'

exports.textGenral = 'GENERAL'
exports.textResolutionDate = 'Planned start date'
exports.textResolutionTime = 'Planned end date'
exports.textTimeRntries = ' View time entries'
exports.textRequesterinformation = 'Requester information'
exports.textTags = 'Tags'
exports.textCategory = 'Category'
exports.textAgent = 'Agent'
exports.textPlannedStartDate = 'Planned start date'
exports.textPlannedEndDate = 'Planned end date'
exports.textDepartment = 'Department'
exports.textPrimaryEmail = 'Primary email'
exports.textViewTickets = 'View recent tickets'
exports.textProperties = 'Properties'
exports.textSource = 'Source'
exports.textType = 'Type'
exports.emptyDataMessage = "No data found";
exports.taskRequesterEndPoint = "bx_block_custom_form/requester_tickets";
exports.placeholderGroupName = "Enter Group Name"
exports.placeholderDescription = "Enter Description";
exports.textAddNewRequester = "Add new requester";
exports.textAddCC = "Add CC";
exports.attchedFile = "Attach File";
exports.getAllDepartmentsApiEndPoint = "bx_block_department/departments";
exports.getAllRequestersApiEndPoint = 'bx_block_department/agent_and_requesters/get_all_requester';
exports.textRequester = "Requester";
exports.getAllGroupsApiEndPoint = 'bx_block_department/groups?user_type=0';
exports.taskGroupFilterAPIEndPoint = "bx_block_tasks/tasks/get_tasks_group";
exports.taskAgentsFilterAPIEndPoint = "bx_block_tasks/tasks/get_all_tasks_agents";
exports.textGroup = "Group";
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.textPlannedSdate = "Planned start date";
exports.textPlannedEdate = "Planned end date";
exports.textPlannedEffort = "Planned effort";
exports.textSearch = "Search";
exports.updatedForm = "Form Updated successfully";
exports.requiredFeild = "Field is required";
exports.groupAutomation = "Group Automations";
exports.groupNameRequire = "Group Name is required";
exports.categoryRequire = "Category is required";
exports.all = "All";
exports.member = "Member";
exports.observer = "Observer";
exports.addAgents = "Add agents";
exports.addObserver = "Add observer";
exports.savechanges = "Save Changes"
exports.subjectRequire = "Field is required";
exports.requesterRequire = "Requester is required";
exports.descRequire = "Description is required";
exports.savechanges = "Save Changes";
exports.statusrequire = "Status is required";
exports.startDateError = "Start date cannot be after end date";
exports.endDateError = "End date cannot be before start date";
exports.noagentsadded = "There are no agents added.";
exports.sucessmessage = "Changes have been applied.";
exports.nameError = "Name has already been taken";
exports.textAll = 'All';
exports.textStatus = 'Status';
exports.textNoTickets = 'No tickets found.'
exports.tasks = "Tasks";
exports.textCreateATicket = "Create a ticket";
exports.alltask = "All Tasks";
exports.open = "Open";
exports.errorMessage = "Unable to reach email server #TAS-1";
exports.general = "GENERAL";
exports.assignedTo = "Assign to";
exports.agentsName = "Sarah Stevens"
exports.noDataFound = "No data found";
exports.textAll = "All";
exports.textStatus = "Status";
exports.textPriority = "Priority";

exports.agentsName = "Sarah Stevens";
exports.parenetType = "Parent type";
exports.taskProperties = "Task Properties";
exports.dueby = "Due by";
exports.applyFilters = "Apply filters"
exports.noDataFound = "No data found";
exports.editTask = "Edit task";
exports.notifyBefore = "Notify before";
exports.dueDate = "Due Date";
exports.note = "Note";
exports.textUpdate = "Update"
exports.agentsName = "Sarah Stevens"
exports.noDataFound = "No data found";
exports.textUpdate = "Update";
exports.updateTaskSucessResponse = "Updated task sucessfully"

exports.agentGroupModalTitle = "Understanding agent groups";
exports.agentGroupInfoMsg1 = "You can organize your agents into specific Groups like “Sales” and “Product Management”. Segmenting them into divisions lets you easily assign tickets, create specific canned responses, manage workflows and generate group-level reports. Note that the same agent can be a member of multiple groups as well";
exports.agentGroupSubtitle1 = "Auto-ticket Assignment";
exports.agentGroupInfoMsg2 = "Once you create homogeneous agent groups, you can choose to automatically assign new tickets in this group to the next agent in Round Robin.";
exports.agentGroupInfoMsg3 = "You can assign a different set of business hours and holidays to each Group. For example, you can separate agents by shifts and assign them different business hours, or create separate groups for each time zone your agents work at.";
exports.agentGroupSubtitle2 = "Working Hours";
exports.agentGroupInfoLink = "Learn more about automatic ticket assignment";

exports.getAllCategoriesEndPoint = "bx_block_categories/categories"
// Customizable Area End
