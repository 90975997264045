import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import RichTextEditor, { EditorValue } from 'react-rte';
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
import { ContentBlock } from 'draft-js';

export type MyProps = { testid:string, checked: boolean; handleClick?: (event: any) => void };

interface AgentDetail {
  id: number;
  full_name: string;
}

export interface RolesListData {
  id: string,
  type: string,
  attributes: {
    name: string;
    description: string;
    role_type: string;
    agent_count?: number
    agent_details: Array<AgentDetail>;
    permissions: Array<any>
  }
}

interface FormInitalValue {
  name: string;
  description: string;
  role_type: string;
  permissions: Array<AllPermission>;
}

type AllPermission = TicketPermission | ProblemPermission | ChangesPermission | ReleasePermission 
| InventoryPermission | SolutionPermission

interface ViewTicketKeys {
  ticketReplyPermission: boolean
}

interface EditNotesKeys {
  editEveryoneNotesPermission: boolean, 
  editOwnNotesPermission: boolean
}

interface EditTicketKeys {
  cancelApprovalsPermission: boolean
}

export interface TicketPermission {
  viewTicketsPermission: ViewTicketKeys;
  editNotesPermission: EditNotesKeys;
  deleteConversationPermission: boolean;
  editTicketPropertiesPermission: EditTicketKeys;
  editTicketTaskPermission: boolean;
  deleteTicketPermission: boolean
}

export interface ProblemPermission {
  viewProblemsPermission: { createProblemsPermission: boolean, editProblemsPermission: boolean,
  deleteProblemsPermission: boolean, createEditTaskPermission: boolean }
}

export interface ChangesPermission {
  viewChagesPermission: { createChangesPermission: boolean, editChangesPermission: boolean,
    deleteChangesPermission: boolean, createEditTaskChangesPermission: boolean
  }
}

export interface ReleasePermission {
  viewReleasesPermission: { createReleasesPermission: boolean, editReleasesPermission: boolean,
    deleteReleasesPermission: boolean, createEditTaskReleasesPermission: boolean
  }
}

export interface InventoryPermission {
  viewAssetsPermission: { createEditAssetPermission: boolean, deleteAssetPermission: boolean };
}

export interface SolutionPermission {
  viewSolutionPermission: { publishSolutionPermission: boolean, deleteSolutionPermission: boolean };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string,
  isLoading: boolean,
  initialValues: FormInitalValue;
  showModal: boolean;
  isInfoModalOpen: boolean;
  agentPermissions: string[];
  agentTicketsPermissions: TicketPermission;
  agentProblemsPermissions: ProblemPermission;
  agentChangesPermissions: ChangesPermission;
  agentReleasesPermissions: ReleasePermission;
  agentInventoryPermissions: InventoryPermission;
  agentSolutionsPermissions: SolutionPermission;
  selectedPermission: string;
  openListIndex: number;
  editorValue: EditorValue;
  currentRole: RolesListData | string;
  errors: {name: string | null};
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateEditAgentRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAgentRoleApiCallId:string = "";
  token: string="";
  getRoleApiCallId: string= "";
  updateAgentRoleApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "",
      isLoading: false,
      initialValues: {
        name: "",
        role_type: "agent_roles",
        description: "",
        permissions: []
      },
      showModal: false,
      isInfoModalOpen: false,
      agentPermissions: this.permissionsList,
      selectedPermission: this.permissionsList[0],
      agentTicketsPermissions: { viewTicketsPermission: { ticketReplyPermission: false },
        editNotesPermission: { editEveryoneNotesPermission: false, editOwnNotesPermission: false },
        deleteConversationPermission: false,
        editTicketPropertiesPermission: { cancelApprovalsPermission: false },
        editTicketTaskPermission: false,
        deleteTicketPermission: false },
      agentProblemsPermissions: { viewProblemsPermission: { createProblemsPermission: false, editProblemsPermission: false,
        deleteProblemsPermission: false, createEditTaskPermission: false }},
      agentChangesPermissions:{ viewChagesPermission: { createChangesPermission: false, editChangesPermission: false,
        deleteChangesPermission: false, createEditTaskChangesPermission: false } },
      agentReleasesPermissions: { viewReleasesPermission: { createReleasesPermission: false, editReleasesPermission: false,
        deleteReleasesPermission: false, createEditTaskReleasesPermission: false }},
      agentInventoryPermissions: { viewAssetsPermission: { createEditAssetPermission: false, deleteAssetPermission: false }},
      agentSolutionsPermissions: { viewSolutionPermission: { publishSolutionPermission: false, deleteSolutionPermission: false } },
      openListIndex: -1,
      editorValue: RichTextEditor.createEmptyValue(),
      currentRole: "",
      errors: { name: null }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    switch (apiRequestId) {
      case this.createAgentRoleApiCallId:
        this.handleCreateUpdateAgentRoleResponse(responseJson, true);
        break;
      case this.getRoleApiCallId:
        this.handleGetRoleResponse(responseJson);
        break;
      case this.updateAgentRoleApiCallId:
        this.handleCreateUpdateAgentRoleResponse(responseJson, false)
        break;
      default:
        console.log("case not matched");
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.token = await getStorageData('authToken');
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.getSpecificAgentRole(id);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  blockStyleFn = (block: ContentBlock) => {      
    const textAlign = block.getData().get('textAlign');
    if (textAlign) {
      switch (textAlign) {
        case 'ALIGN_LEFT':
          return 'align-left';
        case 'ALIGN_CENTER':
          return 'align-center';
        case 'ALIGN_RIGHT':
          return 'align-right';
        case 'ALIGN_JUSTIFY':
          return 'align-justify';
        default:
          return undefined;
      }
    }
    return undefined;    
  };

  permissionsList = [configJSON.ticketsPermissions, 
    configJSON.problemPermissions, configJSON.changesPermissions, configJSON.releasesPermissions,
    configJSON.inventoryPermissions, configJSON.solutionsPermissions];

  handleCreateUpdateAgentRoleResponse(responseJson: {data?: RolesListData, errors?: string[]}, isCreated: boolean) {
    if (responseJson.data) {
      toast.success(`Agent role ${isCreated ? 'Added' : 'updated'} successfully.`);
      this.props.navigation.navigate("Rolesandpermissions");
    }

    if (responseJson.errors) {
      if (typeof responseJson.errors[0] === "object") {
        const error:any = Object.values(responseJson.errors[0])[0] || "";
        toast.error(error);
      }

      else {
        this.setState({ errors: {...this.state.errors, name: responseJson.errors[0] }});
      }
    }
  }

  handleGetRoleResponse(responseJson: { data: RolesListData }) {
    if (responseJson.data) {
      const currentRole = responseJson.data;
      this.setState({ currentRole, editorValue: EditorValue.createFromString(currentRole.attributes.description, "html"), initialValues: 
        {...this.state.initialValues, name: currentRole.attributes.name, 
          description: currentRole.attributes.description, permissions: []}});
      this.setPermissionsForReponse(currentRole.attributes.permissions);
    }
  }

  setPermissionsForReponse(permissions: any) {
    permissions.map((permission: any) => {
      const permissionFields = permission.fields;
      switch(permission.option_name) {
        case configJSON.ticketsPermissions.toLowerCase():
          this.setState({ agentTicketsPermissions: { 
            ...this.state.agentTicketsPermissions, viewTicketsPermission: 
            { ticketReplyPermission: permissionFields.send_a_reply_to_ticket }, editNotesPermission: {
              editEveryoneNotesPermission: permissionFields.edit_everyones_notes, editOwnNotesPermission: permissionFields.edit_only_their_own_notes,
            }, deleteConversationPermission: permissionFields.delete_a_conversation, editTicketPropertiesPermission: {
              cancelApprovalsPermission: permissionFields.cancel_approvals }, editTicketTaskPermission: permissionFields.create_and_edit_tasks_in_ticket,
              deleteTicketPermission: permissionFields.delete_a_ticket
          }})
          break;
        case configJSON.problemPermissions.toLowerCase():
          this.setState({ agentProblemsPermissions: { ...this.state.agentProblemsPermissions, viewProblemsPermission: {
            createProblemsPermission: permissionFields.create_problems, editProblemsPermission: permissionFields.edit_problems, 
            deleteProblemsPermission: permissionFields.delete_problems, createEditTaskPermission: permissionFields.create_edit_tasks_in_problems
          } }})
          break;
        case configJSON.changesPermissions.toLowerCase():
          this.setState({ agentChangesPermissions: { ...this.state.agentChangesPermissions, viewChagesPermission: {
            createChangesPermission: permissionFields.create_changes, editChangesPermission: permissionFields.edit_changes, 
            deleteChangesPermission: permissionFields.delete_changes, createEditTaskChangesPermission: permissionFields.create_edit_tasks_in_changes
          }}});
          break;
        case configJSON.releasesPermissions.toLowerCase():
          this.setState({ agentReleasesPermissions: { ...this.state.agentReleasesPermissions, viewReleasesPermission: {
            createReleasesPermission: permissionFields.create_releases, editReleasesPermission: permissionFields.edit_releases, 
            deleteReleasesPermission: permissionFields.delete_releases, createEditTaskReleasesPermission: permissionFields.create_edit_tasks_in_releases
          }}});
          break;
        case configJSON.inventoryPermissions.toLowerCase():
          this.setState({ agentInventoryPermissions: {...this.state.agentInventoryPermissions, viewAssetsPermission: {
            createEditAssetPermission: permissionFields.create_edit_asset, deleteAssetPermission: permissionFields.delete_asset
          }}});
          break;
        case configJSON.solutionsPermissions.toLowerCase():
          this.setState({ agentSolutionsPermissions: { ...this.state.agentSolutionsPermissions, viewSolutionPermission: {
            publishSolutionPermission: permissionFields.publish_solution, deleteSolutionPermission: permissionFields.delete_solution
          }}});
          break;
      }
    })
  }

  roleBody = (values: FormInitalValue) => {
    const {agentTicketsPermissions, agentProblemsPermissions, agentChangesPermissions, agentReleasesPermissions,
      agentInventoryPermissions, agentSolutionsPermissions
    } = this.state;

    const httpBody = { role: {
      name: values.name,
      description: values.description,
      role_type: values.role_type,
      permissions_attributes: [
        {
          option_name: "tickets",
          fields: {
            send_a_reply_to_ticket: agentTicketsPermissions.viewTicketsPermission.ticketReplyPermission,
            edit_everyones_notes: agentTicketsPermissions.editNotesPermission.editEveryoneNotesPermission,
            edit_only_their_own_notes: agentTicketsPermissions.editNotesPermission.editOwnNotesPermission,
            delete_a_conversation: agentTicketsPermissions.deleteConversationPermission,
            cancel_approvals: agentTicketsPermissions.editTicketPropertiesPermission.cancelApprovalsPermission,
            create_and_edit_tasks_in_ticket: agentTicketsPermissions.editTicketTaskPermission,
            delete_a_ticket: agentTicketsPermissions.deleteTicketPermission
          }
        },
        {
          option_name: "problems",
          fields: {
            create_problems: agentProblemsPermissions.viewProblemsPermission.createProblemsPermission,
            edit_problems: agentProblemsPermissions.viewProblemsPermission.editProblemsPermission,
            delete_problems: agentProblemsPermissions.viewProblemsPermission.deleteProblemsPermission,
            create_edit_tasks_in_problems: agentProblemsPermissions.viewProblemsPermission.createEditTaskPermission
          }
        },
          {
          option_name: "changes",
          fields: {
            create_changes: agentChangesPermissions.viewChagesPermission.createChangesPermission,
            edit_changes: agentChangesPermissions.viewChagesPermission.editChangesPermission,
            delete_changes: agentChangesPermissions.viewChagesPermission.deleteChangesPermission,
            create_edit_tasks_in_changes: agentChangesPermissions.viewChagesPermission.createEditTaskChangesPermission
          }
        },
        {
          option_name: "releases",
          fields: {
            create_releases: agentReleasesPermissions.viewReleasesPermission.createReleasesPermission,
            edit_releases: agentReleasesPermissions.viewReleasesPermission.editReleasesPermission,
            delete_releases: agentReleasesPermissions.viewReleasesPermission.deleteReleasesPermission,
            create_edit_tasks_in_releases: agentReleasesPermissions.viewReleasesPermission.createEditTaskReleasesPermission
          }
        },
        {
          option_name: "inventory",
          fields: {
            create_edit_asset: agentInventoryPermissions.viewAssetsPermission.createEditAssetPermission,
            delete_asset: agentInventoryPermissions.viewAssetsPermission.deleteAssetPermission
          }
        },
        {
          option_name: "solutions",
          fields: {
            publish_solution: agentSolutionsPermissions.viewSolutionPermission.publishSolutionPermission,
            delete_solution: agentSolutionsPermissions.viewSolutionPermission.deleteSolutionPermission
          }
        }
      ]
    }
    }
    return httpBody;
  }

  handleSubmit = (values: FormInitalValue): void => {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.editAgentRole(values);
    } else {
      this.createAgentRole(values);
    }
  }

  editAgentRole(values: FormInitalValue) {
    const id = this.props.navigation.getParam("id");
    const header = {
      'Content-Type': configJSON.fieldsApiContentType,
      token: this.token
    };
    const httpBody = this.roleBody(values);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateAgentRoleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.rolesApiEndpoint}/${id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRolesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createAgentRole(values: FormInitalValue) {
    const header = {
      'Content-Type': configJSON.fieldsApiContentType,
      token: this.token
    };
    const httpBody = this.roleBody(values);
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createAgentRoleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rolesApiEndpoint}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createRoleApiMethod
    );

    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setChecked = (permissionType: string, permissionKey: string, permission: string) => {
    let checked = false;
    if (typeof this.state[permissionType as keyof S][permissionKey as keyof AllPermission] === "boolean") {
      checked = this.state[permissionType as keyof S][permissionKey as keyof AllPermission]
    } else if (isEmpty(permission)) {
      checked = true;
    } else {
      checked = this.state[permissionType as keyof S][permissionKey as keyof AllPermission][permission];
    }
    return checked;
  }

  clearApiError = () => {
    this.setState({ errors: { ...this.state.errors, name: null }});
  }

  handleNavigation = (path:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  getSpecificAgentRole = (id: string | number) => {
    const header = {
      'Content-Type': configJSON.fieldsApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRoleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.rolesApiEndpoint}/${id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRolesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDescription = (value: EditorValue, setFieldValue: (field: string, value: any) => void) => {
    const htmlContent = value.toString('html');
    const isEmptyContent = htmlContent === '<p><br></p>' || !htmlContent.trim();
    this.setState({ editorValue: value });
    setFieldValue('description', isEmptyContent ? '' : htmlContent);
  }

  handleAllPermissionChange = (permissionType: string, permissionKey: string, permission: string, setFieldValue: (field: string, value: any) => void) => {
    const ticketPermission = this.state[permissionType as keyof S][permissionKey as keyof(AllPermission)]
    let checked:boolean = false;
    if (typeof ticketPermission === "boolean") {
      checked = !ticketPermission;
      setFieldValue(`permissions.${permissionType}.${permissionKey}`, checked);
      this.setState((prevState: any) => {
        if (permissionType in prevState) {
          return {
            ...prevState,
            [permissionType]: {
              ...prevState[permissionType],
              [permissionKey]: checked,
            },
          };
        }
        return prevState;
      });      
    } else {
      checked = !ticketPermission[permission as keyof(boolean | ViewTicketKeys | EditNotesKeys | EditTicketKeys)]
      setFieldValue(`permissions.${permissionType}.${permissionKey}.${permission}`, checked);
      this.setState((prevState: any) => {
        const currentPermission = prevState[permissionType][permissionKey as keyof AllPermission];
        if (typeof currentPermission === 'object' && currentPermission !== null) {
          return {
            [permissionType]: {
              ...prevState[permissionType],
              [permissionKey]: {
                ...currentPermission,
                [permission]: checked,
              },
            },
          };
      
        }
        return prevState;
      });          
    }
  }

  handlePermissionChange = (permissionKey: string, permission: string, setFieldValue: (field: string, value: any) => void) => {
    const { selectedPermission, agentPermissions } = this.state;
    switch (selectedPermission) {
      case agentPermissions[0]:
        this.handleAllPermissionChange("agentTicketsPermissions", permissionKey, permission, setFieldValue);
        break;
      case agentPermissions[1]:
        this.handleAllPermissionChange("agentProblemsPermissions", permissionKey, permission, setFieldValue);
        break;
      case agentPermissions[2]:
        this.handleAllPermissionChange("agentChangesPermissions", permissionKey, permission, setFieldValue);
        break;
      case agentPermissions[3]:
        this.handleAllPermissionChange("agentReleasesPermissions", permissionKey, permission, setFieldValue);
        break;
      case agentPermissions[4]:
        this.handleAllPermissionChange("agentInventoryPermissions", permissionKey, permission, setFieldValue);
        break;
      case agentPermissions[5]:
        this.handleAllPermissionChange("agentSolutionsPermissions", permissionKey, permission, setFieldValue);
        break;
      default:
        console.log("no case matched");
    }
  }

  handleSinglePermissionChange = (permissionKey: string, setFieldValue: (field: string, value: any) => void) => {
    const { agentTicketsPermissions } = this.state;
    if (typeof agentTicketsPermissions[permissionKey as keyof(AllPermission)] === 'boolean') {
      this.handlePermissionChange(permissionKey, "", setFieldValue);
    }
  }

  handlePermissions = (permission: string) => {
    this.setState({ selectedPermission: permission });
  }
  // Customizable Area End
}
