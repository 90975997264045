import React from "react";
  // Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Menu,
  Card,
  MenuItem,
  Chip,
  Popover,
} from "@material-ui/core";

import { createTheme, styled } from "@material-ui/core/styles";
import { cancelIcon, downArrow, infoIcon, infoIconOutlined } from './assets'
import RolesandpermissionsController, {
  Props,
  configJSON,
} from "./RolesandpermissionsController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { Toaster } from "react-hot-toast";
import RightSidebar from "../../../components/src/RightSidebar";
import { RoleHeader } from "./RoleHeader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const MainCard = styled(Card)({
  marginBottom: '8px',
  padding: '12px',
  borderRadius: 8,
  border: "1px solid #E6DEED",
  cursor: "pointer"
})

const CardTitle = styled(Typography)({
  width: '100%',
  color: '#33354D',
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
});

const CardBody = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
})
const CustomChip = styled(Chip)({
  background: '#11A64A',
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  "&.MuiChip-clickable.MuiChip-outlined:focus":{
    backgroundColor:'#11A64A'
  }
})
const CustomMenuItem = styled(MenuItem)({
  // padding: '9px 16px'
})
const CardParagraph = styled(Typography)({
  color: "#A3A6C2",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontstyle: 'normal',
  fontweight: 600,
  lineheight: 'normal',
  maxWidth: '400px'
})

const Scrollable = styled(Box)({
  marginBottom :'32px',
  "&::-webkit-scrollbar": {
    width: '10px',
    scrollPadding: '10px'
  },
  "&::-webkit-scrollbar-thumb": {
    background: 'gray',  
  }
})

// Customizable Area End

export default class Rolesandpermissions extends RolesandpermissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInfoIcon() {
    const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
    return (
      <img
        src={icon}
        aria-describedby="infoIcon"
        style={styles.infoIconsStyle}
        onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
        data-test-id='openInfoModal'
      />
    );
  }

  renderInfoModal = () => {
    return (
      <Popover
        data-test-id="infoModal"
        id="infoIcon"
        open={this.state.isInfoModalOpen}
        anchorEl={this.state.anchorEl}
        onClose={() => this.handelInfoModal(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { padding: '15px' },
        }}
      >
        <Box style={styles.modal_main_div}>
          <Box style={styles.modal_head}>
            <Typography style={styles.head_title}>{configJSON.rolesModalTitle}</Typography>
            <img src={cancelIcon} alt="cancel" style={styles.head_icon} data-test-id="infoCancelIcon" onClick={() => this.handelInfoModal(false)} />
          </Box>
          <Box style={styles.modal_body}>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg1}</Typography>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg2}</Typography>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg3}</Typography>
          </Box>

          <Typography style={styles.head_title}>{configJSON.rolesModalSubTitle}</Typography>
          <Box style={styles.modal_body}>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg4}</Typography>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg5}</Typography>
              <Typography style={styles.body_link}>{configJSON.roleInfoMsg6}</Typography>
          </Box>
          <Box >
            <Button style={styles.footer_btn} data-test-id="cancelInfoModal" onClick={() => this.handelInfoModal(false)}>{configJSON.closeBtnLabel}</Button>
          </Box>
        </Box>
      </Popover>
    )
  }
  // Customizable Area End
  
  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster/>
        {this.renderMemberList()}
        <Loader loading={this.state.isLoading} />
        <Box style={styles.container}>
          <RoleHeader heading={configJSON.rolesLabel} renderInfoIcon={this.renderInfoIcon()} />
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box style={styles.rolesList}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span style={styles.card_heading}>
                    {configJSON.rolesLabel}
                  </span>
                  {this.state.RolesList.length !== 0 && (
                    <span style={styles.role_count}>
                      ({this.getListBySelectedRole().length})
                    </span>
                  )}
                </h2>
                <Box>
                  <Button
                    id="basic-button"
                    data-test-id="basicButton"
                    aria-controls={this.state.open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}
                    endIcon={<img src={downArrow} height='20px' width='20px'/>}
                    style={styles.menu_buttton}
                  >
                    {this.state.selectedRoleType === "admin_roles" ? configJSON.adminRole : configJSON.agentRole}
                  </Button>
                  <Menu
                    id="basic-menu"
                    data-test-id="basicMenu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}  
                  >
                    <CustomMenuItem data-test-id="filterRole" onClick={() => this.handleRoleTypeChange(this.state.selectedRoleType === "admin_roles" ? "agent_roles" : "admin_roles" )}>
                      {this.state.selectedRoleType === "admin_roles" ? configJSON.agentRole : configJSON.adminRole }
                    </CustomMenuItem>
                  </Menu>
                </Box>
              </Box>
              <Scrollable style={
                this.state.RolesList.length > 5 ? {
                  overflowY: 'scroll', maxHeight: '455px'
                } : {}
              }>
                {
                  this.getListBySelectedRole().map((role, i) => (
                    <MainCard key={`${role.id}-${i}`} variant="outlined" onClick={() => this.handleEditNavigation(role.id, role.attributes.role_type)}>
                      <CardTitle data-test-id={`roleTitle${i}`}>{this.handleInvalidText(role.attributes.name)}</CardTitle>
                      <CardBody>
                        <CardParagraph dangerouslySetInnerHTML={{ __html: role.attributes.description }} ></CardParagraph>
                        <Box style={styles.listIcon}>
                          <CustomChip
                            data-test-id="showList"
                            label={role.attributes.agent_count + " agent" || " 0 agent"}
                            size="medium"
                            onClick={()=> this.handleMemberListModalOpen(role.attributes.agent_details)}
                          />
                        </Box>
                      </CardBody>
                    </MainCard>
                  ))
                }
              </Scrollable>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "42px",
                }}
              >
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  style={styles.create_button}
                  onClick={this.handleAddClick}
                >
                  {configJSON.addNewRole}
                </Button>
                <Menu
                  id="customized-menu"
                  anchorEl={this.state.addAnchorEl}
                  keepMounted
                  open={Boolean(this.state.addAnchorEl)}
                  onClose={this.handleAddClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem data-test-id="add-admin-role" onClick={() => this.handleNavigation("CreateAdminRole")}>{configJSON.addAdminRole}</MenuItem>
                  <MenuItem data-test-id="add-agent-role" onClick={() => this.handleNavigation("CreateAgentRole")}>{configJSON.addAgentRole}</MenuItem>
                </Menu>
              </Box>
            </Box>
            <RightSidebar data-test-id="rightSidebar" pathName="Rolesandpermissions" handleNavigation={this.handleNavigation} />
          </Box>
        </Box>
        {this.renderInfoModal()}
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  
  heading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
  } as const,
  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    borderRadius: "100px",
    height: "28px",
    border: "1px solid #E6DEED",
    cursor: "pointer",
    backgroundColor: "white",
  } as const,
  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px",
  } as const,
  detailBox: {
    display: "flex", 
    gap: "25%", 
    justifyContent: "space-between", 
    border: "1px solid #E6DEED", 
    alignItems: "center",
    padding: "10px"
  },
  setting: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: "36px",
    backgroundColor: "#E6DEED",
    width: "36px",
    borderRadius: "4px",
  } as const,

  table_heading: {
    color: "#33354D",
    fontSize: "20px",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "normal",
    marginBottom: '30px'
  } as const,
  card_heading:{
    color: "#33354D",
    fontSize: "20px",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "normal",
  } as const,
  role_count: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: "14px",
  } as const,

  rolesList: {
    flex: "1",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)',
    alignSelf: "flex-start",
  } as const,

  create_button: {
    padding: "10px 16px",
    color: "#33354D",
    backgroundColor: "#E6DEED",
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
  } as const,
  cancel_button: {
    backgroundColor: "transparent",
    color: "#8760A9",
    padding: "10px 16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "4px",
    letterSpacing: "0em",
    textTransform: "initial",
  } as const,
  add_button: {
    padding: "10px 16px",
    color: "#FFF",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    letterSpacing: "0em",
    lineHeight: "16px",
    borderRadius: "4px",
    textTransform: "initial",
  } as const,
  accordians: {
    boxShadow: "0px 4px 8px 0px #8760A921",
    flexBasis: "30%",
    padding: "4px",
    borderRadius: "8px",
  } as const,
  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px",
  } as const,
  navigation_item: {
    borderRadius: "4px",
    padding: "9px, 20px, 9px, 16px",
  } as const,

  navigation_item_nested: {
    borderRadius: "4px",
    padding: "9px 20px 9px 30px",
  } as const,

  navigation_item_active: {
    color: "white",
    backgroundColor: "#8760A9",
  } as const,

  navigation_link: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Manrope",
    letterSpacing: "0em",
    lineHeight: "22px",
    color: "#33354D",
  } as const,

  active_link: {
    color: "white",
  } as const,
  form_main_Box: {
    padding: '1rem',
  } as const,
  modal_button_main_Box: {
    gap: '1rem',
    display: 'flex',
    float: 'right',
  } as const,
  menu_buttton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    textTransform: 'none',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 700,
  } as const,

  listIcon: {
    display: "flex",
    alignItems: "center"
  },

  cancel_icon: {
     height: "30px",
      width: "30px" 
  },

  input_label: {
    marginBottom: "5px",
   },

   edit_button: {
    padding: "0px",
    minWidth: "0px" 
   },

   role_span: {
    color: "red"
   },

  modal_main_div: {
    maxWidth: "460px"
  },

  modal_head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px"
  },

  head_title: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: 10
  },

  head_icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },

  modal_body: {
    marginBottom: "30px"
  },

  body_text: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    marginBottom: "10px"
  },

  body_link: {
    color: "#8760A9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "5px"
  },

  footer_btn: {
    display: "flex",
    height: "48px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    background: "#8760A9",
    float: "right",
    color: "white",
    textTransform: "none"
  },

  infoIconsStyle: {
    cursor: "pointer",
    color: "#8760A9",
    marginLeft: "10px",
    marginTop: -8
   },

   customDialogStyle: {
    marginTop: "-250px"
   },

   customLinkStyle: {
    padding: "15px"
   },

   memberListItem: {
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    marginBottom: "8px"
   }

};
// Customizable Area End
