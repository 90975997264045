import React from "react";

// Customizable Area Start
import {
    Button,
    Input,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    TableHead,
    Typography,
    Dialog,
    Box,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    styled,
    Checkbox,
    createTheme,
    ThemeProvider
} from "@material-ui/core";
import {
    MoreVert,
    Search,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Delete,
    Edit,
    ArrowDropDown
} from "@material-ui/icons";
import { Toaster } from 'react-hot-toast';

import RequestersController, { DynamicField, Props, Requester, configJSON } from "./RequestersController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { infoIcon, infoIconOutlined, cancelIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import CommonModal from "./CommonModal.web";
import RightSidebar from "../../../components/src/RightSidebar";

const ErrorText = styled(Typography)({
    color: 'red',
})

const theme = createTheme({
    overrides: {
        MuiCheckbox: {
            root: {
                color: '#8760A9'
            },
            input: {
                borderRadius: '5px'
            },
            colorSecondary: {
                '&$checked': {
                    color: '#8760A9',
                },
            },
        }
    }
})
// Customizable Area End

export default class Requesters extends RequestersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderInfoIcon() {
        const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
        return (
            <img
                src={icon}
                style={styles.infoIconsStyle}
                aria-describedby="infoIcon"
                data-test-id='openInfoModal'
                onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
            />
        );
    }
    renderField = (field: DynamicField) => {
        const { name, column_type } = field.attributes;

        switch (column_type) {
            case 'email':
                case 'number':
                case 'input':
                return (
                    <Field
                        type={column_type}
                        name={name}
                        as={TextField}
                        id={name}
                        variant="outlined"
                        fullWidth
                    />
                );
            case 'textarea':
                return (
                    <Field
                    as={TextField}
                        name={name}
                        id="outlined-multiline-static"
                        multiline
                        variant="outlined"
                        fullWidth
                        minRows={6}
                    />
                );
            case 'select':
                return (
                    <Field
                    name={name}
                    as={Select}
                    fullWidth
                    input={<OutlinedInput />}
                    displayEmpty
                    >
                        {this.getOptions(field).map((x: string | number, i: number) => (
                            <MenuItem value={x} key={`key-${i}-${x}`}>{x}</MenuItem>
                        ))}
                    </Field>
                );
            default:
                return null;
        }
    };
    
    renderEmptyRequesterListMessage() {
        if (this.state.requestersList.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={4} style={styles.truncatedText}>
                        {configJSON.emptyDataMessage}
                    </TableCell>
                </TableRow>
            );
        }
        return null;
    }

    renderTableActions() {
        if (this.state.selected.length > 0) {
            return (
                <Box style={styles.table_action_main}>
                    <Button style={styles.table_action_button}>{configJSON.sendMainLabel}</Button>
                    <Button style={styles.table_action_button}>{configJSON.deactivateLabel}</Button>
                </Box>
            );
        } else {
            return null;
        }
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
                <Toaster />
                <Loader loading={this.state.isLoading} />
                <ThemeProvider theme={theme}>
                    <Box style={styles.container}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span style={styles.navigation}>
                                Settings {">"} User management {">"} Requesters
                            </span>{/*  */}
                            <Box style={styles.more_icon_main_box}>
                                <span style={{  width: '36px', height: '36px'}}><MoreVert style={styles.more_icons_style} /></span>
                            </Box>
                        </Box>
                        <Box style={{  marginBottom: "50px", marginTop: '60px' }}>
                            <Box style={{ display: "flex", gap: "5px" }}>
                                <button style={styles.arrow_btn}><KeyboardArrowLeft style={styles.arrow_icon} /></button>
                                <button style={styles.arrow_btn}><KeyboardArrowRight style={styles.arrow_icon} /></button>
                            </Box>
                            <Box style={styles.requester_main_box}>
                                <h1 style={styles.heading}>{configJSON.requestersLabel}</h1>
                                {this.renderInfoIcon()}
                            </Box>
                        </Box>

                        <Box style={{  position: "relative", marginBottom: "32px", }}>
                            <Search style={styles.search_icon} />
                            <Input
                                placeholder={"Search..."}
                                onChange={(e) => this.setInputValue(e.target.value)}
                                disableUnderline={true}
                                style={styles.input}
                            />
                        </Box>
                        <Box style={{ display: "flex", gap: "20px" }}>
                            <Box style={styles.requestersList}>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "37px",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box style={styles.cardHead} >
                                        <span style={styles.card_heading}>{configJSON.requestersLabel}</span>
                                        <span style={styles.requester_count}>({this.state.requestersList.length})</span>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Button style={styles.export_button_border}>{configJSON.importBTN}</Button><Box style={styles.rightBorder}></Box>
                                        <Button style={styles.export_button_border}>{configJSON.exportBTN}</Button><Box style={styles.rightBorder}></Box>
                                        <Button style={styles.export_button_border} endIcon={<ArrowDropDown />}>{configJSON.AllRequesersBtn}</Button>
                                    </Box>
                                </Box>
                                {this.renderTableActions()}

                                <Box>
                                    <TableContainer>
                                        <Table size="medium">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={this.state.selected.length === this.state.requestersList.length}
                                                            indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.requestersList.length}
                                                            onChange={this.handleSelectAllClick}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={styles.requester_label}>
                                                        {configJSON.nameLabel}
                                                    </TableCell>
                                                    <TableCell style={styles.requester_label}>
                                                        {configJSON.emailLabel}
                                                    </TableCell>
                                                    <TableCell style={styles.requester_label}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.requestersList.length > 0 &&
                                                    this.getTableData().map((requester: Requester, i: number) => (
                                                        <TableRow hover key={`key-${requester.id}-${i}`}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={this.state.selected.indexOf(requester.id) !== -1}
                                                                    onChange={() => this.handleSelect(requester.id)}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.truncatedText}>
                                                                {requester.attributes.full_name}
                                                            </TableCell>
                                                            <TableCell style={styles.truncatedText}>
                                                                {requester.attributes.email}
                                                            </TableCell>
                                                            <TableCell style={styles.tableIcons}>
                                                                <Edit style={styles.button_cursor} onClick={() => this.handelEditRequesters(requester)} />
                                                                <Delete style={styles.button_cursor} onClick={() => this.handleRemoveRequester(requester.id)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                {this.renderEmptyRequesterListMessage()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 15]}
                                        count={this.state.requestersList.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onPageChange={this.onPageChange}
                                        onRowsPerPageChange={this.onRowsPerPageChange}
                                        component="div"
                                    />
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: "42px",
                                    }}
                                >
                                    <Button style={styles.create_button} onClick={() => this.handleModalOpen(true)}
                                        data-test-id="openModal">
                                        {configJSON.addNewRequesterLabel}
                                    </Button>
                                </Box>
                            </Box>
                            <RightSidebar data-test-id="rightSidebar" pathName="Requesters" handleNavigation={this.handleRequesterNavigation} />
                            </Box></Box>
                </ThemeProvider>
                <Dialog
                    maxWidth='md'
                    open={this.state.showModal}
                    onClose={() => this.handleModalOpen(false)}
                    fullWidth={true}
                    data-test-id='close-dialog'
                >
                    <Box p={'1rem'}>
                        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Typography style={styles.table_heading}>
                                {this.renderTableHeading()}
                            </Typography>
                            <span style={styles.modal_cancel_icon} onClick={() => this.handleModalOpen(false)} data-test-id="cancelIcon">
                                <img src={cancelIcon} alt="Cancel..." />
                            </span>
                        </Box>
                        <Formik
                            onSubmit={this.handelSubmit}
                            initialValues={this.state.editMode ? this.convertDataToFormValues(this.state.editRequester) : this.InitialValues()}
                            validationSchema={this.createDynamicValidationSchema}
                        >
                            {({ touched, isSubmitting }: { errors: { [key: string]: string }, touched: { [key: string]: string }, isSubmitting: boolean }) => (
                                <Form translate={undefined} autoComplete="false">
                                    <Grid container spacing={2}>
                                        {this.state.requesterFields.map((requesterField: DynamicField) => {
                                            if (!requesterField.attributes.status) return null;
                                            const { name, title, optional } = requesterField.attributes;
                                            return (<Grid item xs={6} key={`key-${requesterField.id}-${name}`}>
                                                    <InputLabel style={{ marginBottom: "5px" }}>
                                                        {title} {optional === true && <span style={{ color: "red" }}>*</span>}
                                                    </InputLabel>
                                                    {this.renderField(requesterField)}
                                                    {(touched[requesterField.attributes.name] || isSubmitting) &&
                                                        <ErrorMessage name={requesterField.attributes.name} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                                                </Grid>)})}
                                    </Grid>
                                    <Box style={styles.modal_button_main_box}  mt={'30px'} >
                                        <Button type="reset" style={styles.cancel_button} onClick={() => this.handleModalOpen(false)} data-test-id='modal_cancel_btn' >{configJSON.cancelBTN}</Button>
                                        <Button type="submit" style={styles.add_button} >{configJSON.saveBTN}</Button>
                                    </Box>
                                </Form>)}
                        </Formik>
                    </Box>
                </Dialog >

                <CommonModal
                    isOpen={this.state.isInfoModalOpen}
                    title={configJSON.requestersModalTitle}
                    onClose={() => this.handelInfoModal(false)}
                    id="infoIcon"
                    anchorEl={this.state.anchorEl}
                    data={[
                        configJSON.requestersInfoMsg1,
                        configJSON.requestersInfoMsg2
                    ]}
                />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    container: {
        height: "100%",
        paddingBottom: "25px",
        fontFamily: "Manrope",
        margin: "15px 15px 15px 300px",
    } as const,
    arrow_btn: {
        alignItems: "center",
        height: "28px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "100px",
        border: "1px solid #E6DEED",
        width: "28px",
        backgroundColor: "white",
        cursor: "pointer",
    } as const,
    arrow_icon: {
        height: "20px",
        width: "20px",
        color: "#33354D",
    } as const,
    heading: {
        margin: "10px 0  0",
        color: "#33354D",
        fontWeight: "bold",
        fontSize: "30px",
        lineHeight: "40px",
    } as const,
    table_heading: {
        marginBottom: '30px',
        fontSize: "20px",
        color: "#33354D",
        fontWeight: 700,
        lineHeight: "normal",
        fontStyle: "normal",
        fontFamily: 'Manrope',
    } as const,
    card_heading: {
        fontFamily: 'Manrope',
        color: "#33354D",
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "normal",
        fontWeight: 700,
    } as const,
    export_button_border: {
        fontFamily: "Manrope",
        fontWeight: "bolder",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#8760A9",
        letterSpacing: "0em",
        textTransform: "capitalize",
    } as const,
    export_button: {
        fontWeight: "bolder",
        fontFamily: "Manrope",
        fontSize: "16px",
        color: "#8760A9",
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "capitalize",
    } as const,
    requester_count: {
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "19px",
    } as const,
    table_action_main: {
        marginBottom: "10px",
        display: 'flex',
        gap: '8px'
    } as const,
    table_action_button: {
        padding: '10px 16px',
        color: '#33354D',
        fontSize: '16px',
        borderRadius: '4px',
        background: '#E6DEED',
        fontStyle: 'normal',
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: '100%',
    } as const,
    requestersList: {
        borderRadius: "8px",
        flex: "1",
        alignSelf: "flex-start",
        padding: "16px",
        boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)'
    } as const,
    requester_label: {
        color: "#A3A6C2",
        fontSize: "14px",
        fontFamily: "Manrope",
    } as const,
    create_button: {
        backgroundColor: "#E6DEED",
        color: "#33354D",
        padding: "10px 16px",
        fontFamily: "Manrope",
        fontWeight: "bolder",
        fontSize: "16px",
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,
    add_button: {
        color: "#FFF",
        backgroundColor: "#8760A9",
        padding: "10px 16px",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Manrope",
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,
    cancel_button: {
        fontSize: "16px",
        backgroundColor: "transparent",
        padding: "10px 16px",
        color: "#8760A9",
        fontFamily: "Manrope",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,
    accordians: {
        padding: "4px",
        flexBasis: "30%",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px #8760A921",
    } as const,
    navigation: {
        fontSize: "14px",
        lineHeight: "19px",
        fontFamily: "Manrope",
    } as const,
    search_icon: {
        position: "absolute",
        width: "24px",
        left: "10px",
        top: "50%",
        height: "24px",
        color: "#33354D",
        transform: "translateY(-50%)",
    } as const,
    more_icons_style: {
        width: '24px',
        height: '24px',
        color: 'black',
        padding: '5px'
    } as const,
    more_icon_main_box: {
        background: '#e6deed',
        borderRadius: '5px',
    } as const,
    input: {
        height: "40px",
        width: "100%",
        fontFamily: "Manrope",
        fontSize: "16px",
        padding: "10px 10px 10px 40px",
        border: "1px solid #e6deed",
        borderRadius: "10px",
    } as const,
    navigation_item_nested: {
        padding: "9px 20px 9px 30px",
        borderRadius: "4px",
    } as const,
    navigation_item: {
        borderRadius: "4px",
        padding: "9px, 20px, 9px, 16px",
    } as const,
    navigation_item_active: {
        backgroundColor: "#8760A9",
        color: "white",
    } as const,
    navigation_link: {
        fontFamily: "Manrope",
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "bold",
        color: "#33354D",
        letterSpacing: "0em",
    } as const,
    navigation_link_active_color: {
        color: "white",
    } as const,
    cardHead: {
        display: "flex",
        gap: "5px",
        alignItems: "center",
    },
    requester_main_box: {
        alignItems: "center",
        gap: "5px",
        display: "flex",
    } as const,
    modal_button_main_box: {
        display: 'flex',
        gap: '1rem',
        float: 'right',
    } as const,
    truncatedText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px',
        whiteSpace: 'nowrap'
    } as const,
    infoIconsStyle: {
        cursor: 'pointer',
        color: "#8760A9",
        marginLeft: '10px'
    } as const,
    table_buttons: {
        display: 'flex',
        gap: '1rem',
    } as const,
    button_cursor: {
        cursor: 'pointer',
    } as const,
    navigation_menu_bg: {
        backgroundColor: "#8760A9",
    } as const,
    navigation_menu_color: {
        color: "white",
    } as const,
    modal_cancel_icon: {
        width: '30px',
        height: '30px',
        cursor: 'pointer'
    } as const,
    tableIcons: {
        gap: '10px',
        display: 'flex',
    } as const,
    active_link: {
        color: "white",
      } as const,
    rightBorder: {
        width: '2px',  
        background: '#E6DEED',
        height: '20px',
        margin: '0 3px',
        borderRadius: '1px',
    } as const,
};
// Customizable Area End
