import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Container, SvgIcon, Typography, withStyles } from '@material-ui/core';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Box } from "@material-ui/core";
import { FinalLogo1 } from "../../blocks/email-account-login/src/assets";
import { HelpOutline, NotificationsOutlined } from '@material-ui/icons'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getStorageData } from "../../framework/src/Utilities";
const Dashboard = require("./assets/Dashboard.svg") as string;
const Tickets = require("./assets/Tickets.svg") as string;
const Problems = require("./assets/Problems.svg") as string;
const Changes = require("./assets/Changes.svg") as string;
const Tasks = require("./assets/Tasks.svg") as string;
const Assets = require("./assets/Assets.svg") as string;
const Settings = require("./assets/Settings.svg") as string;
const drawerWidth = 200;

export interface Props {
  navigation: any;
  active: string;
  // Customizable Area Start
  role?: String
  // Customizable Area End
}


const customItemStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    width: '210px',
    height: '50px',
    gap: '10px',
    fontFamily: "Manrope",
    fontSize: '14px',
    paddingLeft: '9px',
    borderRadius: "10px",
    color: '#8760a9',
    transition: '.5s ease-out all',
    '&:hover': {
      color: 'white !important',
      background: '#8760a9 !important'
    },
    '&:active': {
      color: 'white !important',
      background: '#8760a9 !important'
    }
  },
})
const CustomNavbarItem = withStyles(customItemStyles)(ListItem)

export default function NavigationSideBar(props: Props) {
  const handleLogout = () => {
    localStorage.clear();
    props.navigation.navigate('EmailAccountLoginBlock');
  };

  const [role, setRole] = useState("");

  useEffect(() => {
    getUser();
  })

  const getUser = async () => {
    let role = await getStorageData("userData")
    setRole(role)
  }

  const settings = () => {
    if (role != "agent") {
      return <CustomNavbarItem button key="Settings" onClick={() => props.navigation.navigate("Settings2")}>
        <img src={Settings} />
        <span

        >Settings</span>
      </CustomNavbarItem>
    }
  }

  return (
    <>
      <div style={webStyles.navbar}>
        <Box sx={webStyles.topFinallogo}>
          <img src={FinalLogo1} alt="My Image" />
        </Box>
        <List style={webStyles.top__content}>
          <CustomNavbarItem style={webStyles.active} button key="Dashboard" onClick={() => props.navigation.navigate("Dashboard")}>
            <img src={Dashboard} />
            <span

            >Dashboards</span>
          </CustomNavbarItem>
          <CustomNavbarItem button key="Tickets" onClick={() => props.navigation.navigate("TaskList")}>
            <img src={Tickets} />
            <span

            >Tickets</span>
          </CustomNavbarItem>
          <CustomNavbarItem button key="Problems" onClick={() => props.navigation.navigate("Cfproblemmanagementticket")}>
            <img src={Problems} />
            <span

            >Problems</span>
          </CustomNavbarItem>
          <CustomNavbarItem button key="Changes" onClick={() => props.navigation.navigate("Cfchangemanagementticket")}>
            <img src={Changes} />
            <span

            >Changes</span>
          </CustomNavbarItem>
          <CustomNavbarItem button key="Tasks" onClick={() => props.navigation.navigate("Tasks")}>
            <img src={Tasks} />
            <span
            >Tasks</span>
          </CustomNavbarItem>
          <CustomNavbarItem button key="Assets" onClick={() => props.navigation.navigate("InventoryManagement")}>
            <img src={Assets} />
            <span

            >Assets</span>
          </CustomNavbarItem>
          {settings()}
          <CustomNavbarItem button key="Logout" onClick={handleLogout}>
            <ExitToAppIcon fontSize="small" />
            <span>Logout</span>
          </CustomNavbarItem>
        </List>
        <div style={webStyles.bottom__content}>
          <span style={webStyles.bottom__links}><HelpOutline style={{ width: '24px', height: '24px' }} /></span>
          <span style={webStyles.bottom__links}><NotificationsOutlined style={{ width: '24px', height: '24px' }} /></span>
          <img src={Dashboard} style={webStyles.bottom__links} width={'44px'} height={'44px'} />
        </div>
      </div>
    </>
  );
}


const webStyles = {
  topFinallogo: {
    margin: 0,
    padding: 0,
    marginBottom: '25px'
  },

  pprBoxWrapper: {
    position: "relative",
  },

  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  navbar: {
    position: 'fixed',
    backgroundColor: 'white',
    width: '250px',
    height: '1024px',
    displat: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    padding: '15px 0px 0px 15px',
    borderRight: '1px solid #e6deed'
  } as const,
  top__content: {
    display: 'flex',
    flexDirection: 'column',
    heigth: '210px',
    gap: '5px'
  } as const,
  navbar__item: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    width: '210px',
    height: '50px',
    gap: '10px',
    fontFamily: "Manrope",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    paddingLeft: '9px',
    borderRadius: "10px",
    color: '#8760a9',
    transition: '.5s ease-out all',
  },
  active: {
    display: 'flex',
    alignItems: 'center',
    width: '210px',
    height: '50px',
    gap: '10px',
    fontFamily: "Manrope",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    paddingLeft: '9px',
    borderRadius: "10px",
    transition: '.5s ease-out all',
    color: 'white !important',
    background: '#8760a9 !important'
  },
  item__links: {
    textDecorration: 'none',
    color: '#8760a9',
    fontSize: '14px',
    transition: '.5s ease-out all'
  },
  bottom__content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '350px',
    gap: '10px',
    paddingLeft: '9px'
  } as const,
  bottom__links: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '44px',
    height: '44px',
    background: '#e6deed',
    borderRadius: '50%',
  }
}