import React from "react";
// Customizable Area Start
import RequesterGroupsController, { Props, configJSON } from "./RequesterGroupsController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { Box, Button, Card, Chip, Dialog, Grid, Input, Popover, styled, TextField, Typography } from "@material-ui/core";
import { CheckBoxRounded, KeyboardArrowRight, MoreVert, Search } from "@material-ui/icons";
import RightSidebar from "../../../components/src/RightSidebar";
import { cancelIcon, infoIcon, infoIconOutlined } from "./assets";
import toast, { ToastBar, Toaster } from "react-hot-toast";
// Customizable Area End

export default class RequesterGroups extends RequesterGroupsController {
  
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  rednderBreadcrumbs = () => {
    return (
      <Box style={styles.breadcrumb_wrapper}>
        <span style={styles.navigation}>
          {configJSON.settingLabel} {<KeyboardArrowRight />} {configJSON.userManagementLabel} {<KeyboardArrowRight />} {configJSON.requesterGroupsLabel}
        </span>
        <Box style={styles.morevert_wrapper}>
          <span style={styles.morevert_span}>
            <MoreVert style={styles.morevert_icon} />
          </span>
        </Box>
      </Box>
    )
  }

  renderRequesterGroupLabelAndCount = () => {
    return (
      <Box style={styles.requesterGroupListBox}>
        <h2 style={styles.requesterGroupListHeading}>
          <span style={styles.card_heading}>
            {configJSON.requesterGroupsLabel}
          </span>
          {this.state.RequesterGroupsList.length !== 0 && (
            <span style={styles.group_count}>
              ({this.state.RequesterGroupsList.length})
            </span>
          )}
        </h2>
      </Box>
    )
  }

  renderRequesterGroupList = () => {
    return (
      <Scrollable style={this.state.RequesterGroupsList.length > 5 ? styles.scrollable_wrapper : {}}>
        {this.state.RequesterGroupsList.length === 0 ? (
          <Typography style={styles.typographyFont}>{configJSON.noDataFound}</Typography>
        ) : (
          this.state.RequesterGroupsList.map((requesterGroup, index: number) => (
            <MainCard key={`${requesterGroup.id}-${index}`} variant="outlined" onClick={() => this.handleClickGroup(requesterGroup.id)}>
              <Box>
                <CardTitle>{requesterGroup.attributes.name || "-"}</CardTitle>
              </Box>
            </MainCard>
          ))
        )}
      </Scrollable>
    )
  }

  renderRequesterInfoIcon() {
    const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
    return (
      <img
        src={icon}
        aria-describedby="infoIcon"
        style={styles.infoIconsStyle}
        onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
        data-testId='openRequesterInfoModal'
      />
    );
  }

  renderRequesterInfoModal = () => {
    return (
      <Popover
        data-testId="infoModal"
        id="infoIcon"
        open={this.state.isInfoModalOpen}
        anchorEl={this.state.anchorEl}
        onClose={() => this.handelRequesterGroupInfoModal(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { padding: '15px', borderRadius: 8 },
        }}
      >
        <Box style={styles.modal_main_div}>
          <Box style={styles.modal_head}>
            <Typography style={styles.head_title}>{configJSON.requesterGroupModalTitle}</Typography>
            <img src={cancelIcon} alt="cancel" style={styles.head_icon} data-testId="infoCancelIcon" onClick={() => this.handelRequesterGroupInfoModal(false)} />
          </Box>
          <Box style={styles.modal_body}>
            <Typography style={styles.body_text}>{configJSON.requesterGroupSubtitle}</Typography>
            <Box display="flex" gridGap={8}>
              <CheckBoxRounded style={{ color: "#11A64A", fontSize: 24 }} /><Typography style={styles.body_text}>{configJSON.requesterGroupInfoMsg1}</Typography>
            </Box>
            <Box display="flex" gridGap={8}>
              <CheckBoxRounded style={{ color: "#11A64A", fontSize: 24 }} /><Typography style={styles.body_text}>{configJSON.requesterGroupInfoMsg2}</Typography>
            </Box>
            <Box display="flex" gridGap={8}>
              <CheckBoxRounded style={{ color: "#11A64A", fontSize: 24 }} /><Typography style={styles.body_text}>{configJSON.requesterGroupInfoMsg3}</Typography>
            </Box>
            <Typography style={styles.body_text}>{configJSON.requesterGroupInfoFinalMsg}</Typography>
          </Box>

          <Box >
            <Button style={styles.closeBtn} data-testId="cancelInfoModal" onClick={() => this.handelRequesterGroupInfoModal(false)}>{configJSON.closeBtnLabel}</Button>
          </Box>
        </Box>
      </Popover>
    )
  }

  renderNextPrevButton = () => {
    return (
      <Box style={styles.arrow_wrapper}>
        <Box style={styles.label_heading}>
          <h1 style={styles.heading}>{configJSON.requesterGroupsLabel}</h1>
          {this.renderRequesterInfoIcon()}
        </Box>
      </Box>
    )
  }

  renderSearchBar = () => {
    return (
      <Box style={styles.search_wrapper}>
        <Search style={styles.search_icon} />
        <Input
          disableUnderline={true}
          value={this.state.searchGroup}
          onChange={this.handleSearchGroup}
          style={styles.input}
          placeholder={configJSON.searchPlacholder}
          data-testId={"search_group"}
        />
      </Box>
    )
  }

  renderCreateRequesterGroupsModal = () => {
    return (
      <Dialog
        open={this.state.newRequesterModal}
        onClose={this.handleNewRequesterModalClose}
        fullWidth={true}
        maxWidth="sm"
        data-testId={"create-dialog"}
      >
        <Box p={"1rem"}>
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography style={styles.modal_heading}>{configJSON.addGroupLabel}</Typography>
            <span style={styles.cancelIconSpan} onClick={this.handleNewRequesterModalClose} data-test-id="cancelIcon">
              <img src={cancelIcon} alt={configJSON.cancelIconAltText} />
            </span>
          </Box>
          <Typography style={styles.generalFontStyle}>{configJSON.generalLabel}</Typography>
          <Grid container spacing={4} style={{ marginTop: '1%' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box style={styles.boxStyle_4}>
                <Typography style={styles.inputLabel}>{configJSON.groupNameLabel}<span style={styles.textColorRed}> *</span></Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={configJSON.placeholderGroupName}
                  value={this.state.groupName}
                  onChange={this.handleChangeGroupName}
                  data-testId={"changeGroupName"}
                />
                {this.state.groupNameError && <Typography style={styles.error} data-testid="groupNameError">{this.state.groupNameError}</Typography>}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box style={styles.boxStyle_4}>
                <Typography style={styles.inputLabel}>{configJSON.textDescription}</Typography>
                <TextField
                  variant="outlined"
                  placeholder={configJSON.placeholderTextDescription}
                  fullWidth
                  minRows={11}
                  multiline
                  data-testId={"description"}
                  value={this.state.description}
                  onChange={this.handleDescription}
                />
              </Box>
            </Grid>

            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
              <Box style={styles.buttonBox}>
                <Button onClick={this.handleNewRequesterModalClose} data-testId='close_modal' style={styles.cancelButton}>{configJSON.cancelBTN}</Button>
                <Button onClick={this.addRequesterGroup} data-testId="add_requester" variant="contained" style={styles.submitButton}>{configJSON.addBtn}</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  }
  // Customizable Area End

  render(): React.ReactNode {
    // Customizable Area Start
    return (
      <React.Fragment>
        <NavigationSideBar active={"Settings"} navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <Toaster toastOptions={{
          duration: 4000,
          style: {
            maxWidth: 'none'
          }
        }}>
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <div style={{ display: "flex", alignItems: 'center' }}>
                  {icon}
                  <span style={styles.toastMessage}>{message}</span><span style={{ ...styles.toastGroupName, marginLeft: 40, marginRight: 8}}>{t.id}</span>
                | <Button style={{ minWidth: 20, marginTop: 2 }} data-testid="create-toaster-btn" onClick={() => toast.dismiss(t.id)}>
                    X
                  </Button>
                </div>
          )}
        </ToastBar>
        )}
      </Toaster><Box style={styles.container}>
          {this.rednderBreadcrumbs()}
          {this.renderNextPrevButton()}
          {this.renderSearchBar()}
          <Box style={styles.requesterGroupListWrapper}>
            <Box style={styles.requesterGroupList}>
              {this.renderRequesterGroupLabelAndCount()}
              {this.renderRequesterGroupList()}
              <Box style={styles.addNewButtonWrapper}>
                <Button style={styles.addNew}
                  onClick={this.handleNewRequesterModalOpen}
                  data-test-id="openRequesterGroupModal">
                  {configJSON.addNewRequesterGroup}
                </Button>
              </Box>
            </Box>
            <RightSidebar data-test-id="rightSidebar" pathName="RequesterGroup" handleNavigation={this.handleNavigation} />
            {this.renderCreateRequesterGroupsModal()}
            {this.renderRequesterInfoModal()}
          </Box>
        </Box>
      </React.Fragment>
    )
    // Customizable Area End
  }

}

// Customizable Area Start
const Scrollable = styled(Box)({
  marginBottom: "32px",
  "&::-webkit-scrollbar": {
    width: "10px",
    scrollPadding: "10px"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "gray"
  }
});

const MainCard = styled(Card)({
  marginBottom: "8px",
  padding: "12px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "8px",
  cursor: "pointer"
});

const CardTitle = styled(Typography)({
  width: "100%",
  color: "#33354D",
  fontFamily: "Manrope",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope"
  },

  inputLabel: {
    fontFamily: 'Manrope',
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500
  },

  scrollable_wrapper: {
    overflowY: "scroll",
    maxHeight: "455px"
  },

  textColorRed: {
    color: 'red'
  },

  requesterGroupListBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "37px"
  },

  closeBtn: {
    display: "flex",
    height: "48px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    background: "#8760A9",
    float: "right",
    color: "white",
    textTransform: "none"
  },

  modal_heading: {
    color: "#33354D",
    fontStyle: "normal",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "normal",
    marginBottom: "30px"
  },

  requesterGroupListHeading: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },

  card_heading: {
    color: "#33354D",
    fontSize: "20px",
    fontFamily: "Manrope",
    fontWeight: 700,
  },

  group_count: {
    lineHeight: "19px",
    fontWeight: 600,
    fontSize: "14px"
  },

  modal_main_div: {
    maxWidth: "460px"
  },

  modal_head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px"
  },

  head_title: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: 10
  },

  head_icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },

  modal_body: {
    marginBottom: "30px"
  },

  body_text: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    marginBottom: "10px"
  },

  typographyFont: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: 'center'
  },

  toastMessage: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Manrope",
    color: "#33354D"
  },

  toastGroupName: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Manrope",
    color: "#8760A9"
  },

  arrow_wrapper: {
    marginTop: "60px",
    marginBottom: "50px"
  },

  addNewButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 42
  },

  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'initial' as const,
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 25px 5px 25px',
  },

  addNew: {
    padding: "10px 16px",
    color: "#33354D",
    backgroundColor: "#E6DEED",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px"
  },

  infoIconsStyle: {
    cursor: "pointer",
    color: "#8760A9",
    marginLeft: "10px",
    marginTop: -8
   },

  arrow_btn_wrapper: {
    display: "flex",
    gap: "5px"
  },

  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    borderRadius: "100px",
    height: "28px",
    border: "1px solid #E6DEED",
    cursor: "pointer",
    backgroundColor: "white"
  },

  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px"
  },

  error: {
    fontSize: 12,
    fontFamily: 'Manrope',
    color: '#F22A2A'
  },

  heading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold"
  },

  label_heading: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },

  requesterGroupListWrapper: {
    display: "flex",
    gap: "20px"
  },

  requesterGroupList: {
    flex: "1",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    alignSelf: "flex-start"
  },

  breadcrumb_wrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  morevert_wrapper: {
    background: "#e6deed",
    borderRadius: "5px"
  },

  morevert_span: {
    width: "36px",
    height: "36px"
  },

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex"
  },

  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px"
  },

  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px"
  },

  info_color: {
    color: "#8760A9"
  },

  search_wrapper: {
    position: "relative",
    marginBottom: "32px"
  },

}
// Customizable Area End