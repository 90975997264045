import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";
import * as Yup from 'yup';

interface InitialValues {
  name: string;
  description: string;
}

interface CabData {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    agents: Agent[];
  };
}

interface Agent {
  id: number;
  full_name: string;
  email: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  isLoading: boolean;
  txtInputValue: string;
  openListIndex: number;
  showModal: boolean;
  initialValues: InitialValues;
  cabsList: CabData[]
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllCabsApiCallId: string = ''
  createCabApiCallId: string = ''
  token: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      isLoading: false,
      txtInputValue: '',
      openListIndex: -1,
      showModal: false,
      initialValues: { name: '', description: '' },
      cabsList: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    // This will rerdirect user to login page if token is not valid 
    if (Array.isArray(responseJson?.errors) && responseJson?.errors[0].token == "Token has Expired") {
      this.goToLogin();
    }

    switch (apiRequestId) {
      case this.getAllCabsApiCallId:
        this.handleCabsApiDataResponse(responseJson)
        break;
      case this.createCabApiCallId:
        this.handleCreateCabResponse(responseJson)
        break;
      default:
        break;
    }

    // Customizable Area End
  };
  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken')
    this.getAllCabData()
  }

  getAllCabData = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCabsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllCabsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLogin() {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setInputValue = (text: string) => this.setState({ txtInputValue: text });

  openAccordian = (index: number) => this.setState((prevState) => ({ openListIndex: prevState.openListIndex === index ? -1 : index }))

  handleModalOpen = (value: boolean) => this.setState({ showModal: value })

  navigateUser = (id: string) => {
    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationCabDetailsMessage)
    );

    navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToLoginMessage.addData(getName(MessageEnum.UrlPathParamMessage), id);
    this.send(navigateToLoginMessage);
  }

  handleNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  checkValue = (value: string) => !value || value.trim().length > 0
  validationSchema = Yup.object({
    name: Yup.string().trim().required('This field is required').min(4, 'Please enter at least 4 characters'),
    description: Yup.string().test('not-only-whitespace', 'This field cannot be only whitespace', this.checkValue )
  });

  handleSubmit = (value: InitialValues) => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const httpBody = {
      cab: {
        ...value
      }
    }

    this.setState({ isLoading: true, showModal: false });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCabApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCabApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // It will handel agent list response
  handleCabsApiDataResponse = (responseJson: { data: CabData[] }) => {
    if (responseJson?.data) {
      this.setState({
        cabsList: responseJson.data,
      })
    }
  }

  handleCreateCabResponse = (responseJson: { data: CabData }) => {
    if (responseJson?.data) {
      this.getAllCabData()
      this.setState({
        initialValues: { name: '', description: '' }
      })
      toast.success(responseJson.data.attributes.name)
    }
  }
  // Customizable Area End
}
