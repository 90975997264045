import toast from "react-hot-toast";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");

export interface RequesterData {
  id: number | string;
  full_name: string;
  email: string,
  agent_type: "member"
}

export interface ResponseJson {
    data?: ResponseData;
    errors?: any;
    message?: string;
}

export interface ResponseData {
  id: string;
  type: string;
  attributes: ResponseAttributes;
}

export interface ResponseAttributes {
  name: string;
  description: string;
  business_function?: string | null;
  automation_time?: string | null;
  escalation_email?: string | null;
  user_type: string;
  business_hour: {
      data: string | null;
  },
  category: {
      data: string | null;
  },
  agents: Array<any>;
}

export interface RequesterList {
  id: number | string,
  attributes: {
    full_name: string;
    email: string;
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  groupId: number | null
  currentRequesterGroup: any;
  searchRequester: string;
  openEditModal: boolean;
  groupName: string;
  groupNameError: string;
  description: string;
  requesterList: RequesterList[];
  openAddRequesterModal: boolean;
  members: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
  
export default class RequesterGroupsMemberController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getRequesterGroupAPICallId: string="";
  updateRequesterGroupAPICallId: string="";
  getAllRequesterApiCallId: string="";
  addRequesterMemberAPICallId: string="";
  getSearchRequestersAPICallId: string="";
  token: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
       // Customizable Area Start
      isLoading: false,
      groupId: null,
      searchRequester: "",
      currentRequesterGroup: undefined,
      openEditModal: false,
      groupName: "",
      groupNameError: "",
      description: "",
      requesterList: [],
      openAddRequesterModal: false,
      members: [""]
       // Customizable Area End
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.token = await getStorageData('authToken');
    const groupId = this.props.navigation.getParam('id', 0);
    this.getAllRequesters();
    this.setState({ groupId });
    this.getRequesterGroup();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
        isLoading: false
    })
    switch (apiRequestCallId) {
        case this.getRequesterGroupAPICallId:
          this.handleCurrentRequesterGroupResponse(responseJson);
          break;
        case this.updateRequesterGroupAPICallId:
          this.handleUpdateRequesterGroupResponse(responseJson);
          break;
        case this.getAllRequesterApiCallId:
          this.handleRequestersResponse(responseJson);
          break;
        case this.addRequesterMemberAPICallId:
          this.handleAddMembersToRequesterGroup(responseJson);
          break;
        case this.getSearchRequestersAPICallId:
          this.handleSearchRequestersResponse(responseJson);
          break;
        default:
          break;
    }
    // Customizable Area End
}
  // Customizable Area Start
  handleCurrentRequesterGroupResponse (responseJson: ResponseJson) {
    this.setState({ isLoading: false });
    if (responseJson.data) {
      const currentRequesterGroup = responseJson.data;
      this.setState({ currentRequesterGroup: currentRequesterGroup, groupName: currentRequesterGroup.attributes.name,
        description: currentRequesterGroup.attributes.description,
        members: responseJson.data.attributes.agents.map((requester: RequesterData) => requester.id.toString())
       });
    }
  }

  handleUpdateRequesterGroupResponse (responseJson: ResponseJson) {
    if (responseJson.data) {
      this.setState({ openEditModal: false });
      toast.success(configJSON.editGroupSuccessMessage);
      this.getRequesterGroup();
    }
    if (responseJson.errors) {
      if (typeof (responseJson.errors[0]) === "object") {
        this.setState({ openEditModal: false });
        toast.error(responseJson.errors[0][Object.keys(responseJson.errors[0])[0]])
      } else {
        this.setState({ groupNameError: responseJson.errors[0] });
      }
    }
  }

  handleRequestersResponse (responseJson: { data: ResponseData[] }) {
    if (responseJson?.data) {
      const requestersData = responseJson.data.map((requester: any) => ({
        id: requester.id,
        attributes: {
          full_name: requester.attributes.full_name || '',
          email: requester.attributes.email || ''
        }
      }));
      this.setState({
        requesterList: requestersData
      })
    }
  }

  handleAddMembersToRequesterGroup (responseJson: ResponseJson) {
    if (responseJson.data) {
      toast.success("New members have been added.");
      this.handleCloseMembersDialog();
      this.getRequesterGroup();
    }
  }

  handleSearchRequestersResponse (responseJson: ResponseJson) {
    if (responseJson.data) {
      this.setState({ currentRequesterGroup: responseJson.data });
    }
    if (responseJson.message) {
      this.setState({ currentRequesterGroup: undefined });
    }
  }

  handleSearchGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchRequester = event.target.value;
    this.setState({ searchRequester }, () => {
      if (searchRequester.trim() === '') {
          this.getRequesterGroup();
      } else {
          this.getSearchRequestersAPI();
      }
    });
  }

  closeEditGroupModal = () => {
    const { currentRequesterGroup } = this.state;
    this.setState({ openEditModal:  false, groupNameError: "", groupName: currentRequesterGroup.attributes.name, 
      description: currentRequesterGroup.attributes.description });
  }

  handleChangeGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const groupname = event.target.value;
    const isNameEmpty = groupname.trim() === '';
    this.setState({ groupName: groupname, groupNameError:  isNameEmpty ? configJSON.groupNameRequire : '' });

  }

  handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: event.target.value });
  }

  openRequesterGroupEditModal () {
    this.setState({ openEditModal: true, groupNameError: "" });
  }

  addRequesterManually () {
    const members = this.state.members.length > 0 ? this.state.members : [""];
    this.setState({ openAddRequesterModal: true, members });
  }

  getRequesterGroup = () => {
    this.setState({ isLoading: true });
    const header = { "Content-Type": configJSON.apiContentType, 
      token: this.token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRequesterGroupAPICallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getParticularGroupAPIEndPoint + this.state.groupId
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllRequesters = () => {
    console.log("in all requesters");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllRequesterApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRequestersApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchRequestersAPI = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
  };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchRequestersAPICallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getParticularGroupAPIEndPoint + this.state.groupId}/search_agent?search=${this.state.searchRequester}&user_type=requester`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateForm = () => {
    if (this.state.groupName.trim() === '') {
      this.setState({ groupNameError: 
        configJSON.groupNameRequire });
      return false;
    }

    let regex = /^[^a-zA-Z0-9]+$/;
    if (regex.test(this.state.groupName.trim())) {
      this.setState({ groupNameError: configJSON.groupNameSpecialCharError });
      return false;
    }

    return true;
  }

  updateRequestGroup = async () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({ isLoading: true });

    const header = { "Content-Type": configJSON.apiContentType, 
      token: this.token
    };

    const httpBody = {
      group: {
        description: this.state.description,
        name: this.state.groupName,
        user_type: "requester"
      }
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateRequesterGroupAPICallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getParticularGroupAPIEndPoint + this.state.groupId
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateRequesterApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRemoveMember = (index: number) => {
    this.setState((prevState) => {
      const members = [...prevState.members];
      members.splice(index, 1);
      return { members };
    });
  }

  handleSelectChange = (event: any, index: number) => {
    const value = event.target.value as string;
    this.setState((prevState) => {
        const updatedList = [...prevState.members];
        updatedList[index] = value;
        return { ...prevState, members: updatedList };
    });
  }

  handleAddMember = () => {
    this.setState({ members:   [...this.state.members, '']  })
  }

  isAllRequestersAdded = () => {
    const totalAgents = this.state.requesterList.length;
    const totalSelected = this.state.members.length;
    return totalSelected >= totalAgents;
  }

  handleCloseMembersDialog = () => {
    this.setState({ openAddRequesterModal: false, 
      members: this.state.currentRequesterGroup.attributes.agents.map((requester: RequesterData) => requester.id.toString()) })
  }

  addRequesterToGroup = async () => {
    this.setState({ isLoading: true });
    const groupid = this.state.groupId;
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token
    };

    const httpBody = {
      agent_and_requester_params: [
        ...this.state.members.filter((member) => member).map((memberId) => ({
            agent_id: memberId,
            agent_type: "member"
        })),
      ]
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getParticularGroupAPIEndPoint}${groupid}/add_members_and_observers`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );
    this.addRequesterMemberAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}