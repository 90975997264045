import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

interface AgentDetail {
  id: number;
  full_name: string;
}

export interface RolesListData {
  id: string,
  type: string,
  attributes: {
    name: string;
    description: string;
    role_type: string;
    agent_count?: number
    agent_details: Array<AgentDetail>;
  }
}

interface ResponseJson {
  data: RolesListData[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean,
  RolesList: RolesListData[];
  selectedRoleType: string;
  anchorEl: any;
  addAnchorEl: any;
  isAddOpen: boolean;
  open: boolean;
  showModal: boolean;
  isInfoModalOpen: boolean;
  adminPermissions: string[];
  openListIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesandpermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRolesCallID:string = "";
  token: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      RolesList: [],
      anchorEl: null,
      addAnchorEl: null,
      isAddOpen: false,
      open: false,
      selectedRoleType: "admin_roles",
      showModal: false,
      isInfoModalOpen: false,
      adminPermissions: this.permissionsList,
      openListIndex: -1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    if (this.getAllRolesCallID === apiRequestId) {
      this.handleRolesApiDataResponse(responseJson)
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken')
    this.getAllRoles();
  }

  handleRolesApiDataResponse = (responseJson: ResponseJson) => {
    this.setState({ isLoading: false })
    if (responseJson && responseJson.data) {
      this.setState({
        RolesList: responseJson.data
      });
    }
  }

  permissionsList = [configJSON.permissionOne, 
    configJSON.permissionTwo, configJSON.permissionThree, configJSON.permissionFour,
    configJSON.permissionFive, configJSON.permissionSix, configJSON.permissionSeven,
    configJSON.permissionEight, configJSON.permissionNine];

  getAllRoles() {
      this.setState({ isLoading: true });
      const header = {
        "Content-Type": configJSON.rolesApiContentType,
        token: this.token
      };
      let getRolesMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      getRolesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getRolesApiMethod
      );
      getRolesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.rolesApiEndpoint
      );
      getRolesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.getAllRolesCallID = getRolesMessage.messageId;
      runEngine.sendMessage(getRolesMessage.id, getRolesMessage);
    }

  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  }

  handleAddClick = (event: any) => {
    this.setState({
      addAnchorEl: event.currentTarget,
      isAddOpen: true,
    });
  }

  handleAddClose = () => {
    this.setState({
      addAnchorEl: null,
      isAddOpen: false,
    });
  }

  getListBySelectedRole() {
    return this.state.RolesList.filter(role => role.attributes.role_type === this.state.selectedRoleType);
  }

  handleMemberListModalOpen = (agent_details: AgentDetail[]) => {

  }

  handleRoleTypeChange = (roleType: string) => {
    this.setState({ selectedRoleType: roleType });
    this.handleClose();
  };

  handleInvalidText = (text: string) => {
    return text ? text : '-'
  }

  renderMemberList = () => {

  }

  handelInfoModal(value: boolean){
    this.setState({
      isInfoModalOpen: value
    })
  }

  handleNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(message)
  }

  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
        isInfoModalOpen: true,
        anchorEl: event?.currentTarget,
    });
  };

  handleEditNavigation = (id: string | number, roleType: string) => {
    const editPath = roleType === "admin_roles" ? "EditAdminRole" : "EditAgentRole";
    this.props.navigation.navigate(editPath, { id: id })
  }
  // Customizable Area End
}
