import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
export const configJSON = require("./config");

export interface RequesterGroupsListData {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    business_hours: string;
    business_function: string;
    automation_time: string;
    escalation_email: string;
    user_type: string;
    requesters: {
      id: number;
      full_name: string;
      email: string;
    }[]
  }
}
interface ResponseJson {
  data: Array<RequesterGroupsListData>;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type ListItems = {
  title: string,
  href: string
}

type NavigationItem = {
  title:string,
  list:ListItems[]
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  searchGroup: string;
  RequesterGroupsList: Array<RequesterGroupsListData>;
  page: number;
  rowsPerPage: number;
  openListIndex: number;
  newRequesterModal: boolean;
  groupName: string;
  groupNameError: string;
  selectedFunction: string;
  buisnessFunctionError: string;
  selectedBuisnessHours: string;
  description: string;
  showMembers: boolean;
  groupId: string | null
  isInfoModalOpen: boolean;
  anchorEl: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequesterGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRequesterGroupsCallId: string = "";
  addRequesterGroupAPICallId: string = "";
  token: string = "";
  getSearchGroupAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page: 0,
      isLoading: false,
      searchGroup: "",
      RequesterGroupsList: [],
      rowsPerPage: 5,
      openListIndex: -1,
      newRequesterModal: false,
      selectedBuisnessHours: '15 minutes',
      groupName: '',
      groupNameError: '',
      selectedFunction: '',
      buisnessFunctionError: '',
      description: '',
      showMembers: false,
      groupId: null,
      isInfoModalOpen: false,
      anchorEl: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    switch (apiRequestCallId) {
      case this.getAllRequesterGroupsCallId:
        this.handleAllRequesterGroupApiDataResponse(responseJson);
        break;
      case this.getSearchGroupAPICallId:
        this.handleSearchGroupAPIResponse(responseJson);
        break;
      case this.addRequesterGroupAPICallId:
        this.handleCreateRequesterGroupAPIResponse(responseJson);
        break;
      default:
        console.log("no match");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken');
    this.getAllRequesterGroups();
  }

  // Web Events
  handleChangeGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const groupname = event.target.value;
    const isNameEmpty = groupname.trim() === '';
    this.setState({
      groupName: groupname,
      groupNameError: isNameEmpty ? configJSON.groupNameRequire : ''
    });
  }

  handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: event.target.value })
  }

  handleCreateRequesterGroupAPIResponse = (responseJson: {data?: RequesterGroupsListData, errors: any }) => {
    if (responseJson.data) {
      toast.success("New group has been added.", { id: responseJson.data.attributes.name });
      this.handleNewRequesterModalClose();
      this.getAllRequesterGroups();
    }
    if (responseJson.errors) {
      if (typeof (responseJson.errors[0]) === "object") {
        toast.error(responseJson.errors[0][Object.keys(responseJson.errors[0])[0]])
        this.handleNewRequesterModalClose();
      } else {
        this.setState({ groupNameError: responseJson.errors[0] });
      }
    }
  }

  handleClickGroup = async (index: string) => {
    await setStorageData("groupId", index)
    this.setState({ showMembers: true })
    this.props.navigation.navigate('RequesterGroupsMember', { id: index });
  }

  handleNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleSearchGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchGroup = event.target.value;
    this.setState({ searchGroup }, () => {
      if (searchGroup.trim() === '') {
        this.getAllRequesterGroups();
      } else {
        this.getSearchGroupAPI();
      }
    });
  };

  // API Integration

  getAllRequesterGroups = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.getRequesterGroupsApiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllRequesterGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRequesterGroupsApiEndPoint}?user_type=1`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRequesterGroupsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handelRequesterGroupInfoModal = (modalOpen: boolean) => {
    this.setState({ isInfoModalOpen: modalOpen });
  }

  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
        isInfoModalOpen: true,
        anchorEl: event?.currentTarget,
    });
  };

  getSearchGroupAPI = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchGroupAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchGroupAPIendPoint}?search=${this.state.searchGroup}&user_type=requester`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateForm = () => {
    if (this.state.groupName.trim() === '') {
      this.setState({ groupNameError: configJSON.groupNameRequire });
      return false;
    }
    let regex = /^[^a-zA-Z0-9]+$/
    if (regex.test(this.state.groupName.trim())) {
      this.setState({ groupNameError: configJSON.groupNameSpecialCharError });
      return false;
    }

    return true;
  }

  addRequesterGroup = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const httpBody = {
      group: {
        description: this.state.description,
        name: this.state.groupName,
        user_type: "requester"
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRequesterGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.addRequesterGroupAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openAccordian = (index: number) => {
    this.setState((prevState) => ({ openListIndex: prevState.openListIndex === index ? -1 : index }));
  };
  // API Response

  handleAllRequesterGroupApiDataResponse = (responseJson: ResponseJson) => {
    this.setState({ isLoading: false });
    if (responseJson?.data) {
      this.setState({
        RequesterGroupsList: responseJson.data
      })
    }
  };

  handleSearchGroupAPIResponse = (responseJson: ResponseJson) => {
    if (responseJson?.data) {
      this.setState({
        RequesterGroupsList: responseJson.data
      })
    }
  }

  handleNewRequesterModalOpen = () => {
    this.setState({ newRequesterModal: true, groupName: "", description: "" });
  };

  handleNewRequesterModalClose = () => {
    this.setState({ newRequesterModal: false, groupName: "", description: "" });
  };
  // Customizable Area End
}
