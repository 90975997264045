import React from "react";

// Customizable Area Start
import {
  Button,
  Input,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  styled,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  createTheme,
  ThemeProvider,
  Dialog,
} from "@material-ui/core";
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close
} from "@material-ui/icons";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import UserFieldsController, { DynamicField, Props, configJSON } from "./UserFieldsController";
import CommonModal from "./CommonModal.web";
import { infoIcon, infoIconOutlined } from "./assets";
import { Toaster } from 'react-hot-toast';
import RightSidebar from "../../../components/src/RightSidebar";

const CustomCard = styled(Box)({
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #E6DEED',
  borderRadius: '8px'
});

const CustomText = styled(Typography)({
  color: '#33354D',
  fontFaily: 'Manrope',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
})

const FieldRow = styled(Box)({
  width: '100%',
  marginBottom: '0.5rem',
});

const DialogFieldRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '0.5rem',
  gap: '1rem'
});

const theme = createTheme({
  overrides: {
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    }
  },
})


// Customizable Area End

export default class UserFields extends UserFieldsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAgentFields() {
    const { agentFields } = this.state;
    if (agentFields.length === 0) {
      return configJSON.noDataMessage;
    }

    return agentFields.map((field, index) => {
      if (!field.attributes.status) {
        return null;
      }

      return (
        <CustomCard key={`key-${index}`}>
          <FieldRow><CustomText>{field.attributes.title}</CustomText></FieldRow>
        </CustomCard>
      );
    });
  }

  renderRequesterFields() {
    const { requesterFields } = this.state;
    if (requesterFields.length === 0) {
      return configJSON.noDataMessage;
    }

    return requesterFields.map((field, index) => {
      if (!field.attributes.status) {
        return null;
      }

      return (
        <CustomCard key={`key-${index}`}>
          <FieldRow><CustomText>{field.attributes.title}</CustomText></FieldRow>
        </CustomCard>
      );
    });
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster />
        <ThemeProvider theme={theme}>
          <Box style={styles.container}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={styles.navigation}>
                Settings {">"} User management {">"} UserFields
              </span>
              <Box style={styles.moreIconMainStyle}>
                <span style={{ height: '36px', width: '36px' }}>
                  <MoreVert style={styles.moreIconStyle} />
                </span>
              </Box>
            </Box>

            <Box style={{ marginTop: "60px", marginBottom: "50px", }}>
              <Box style={{ gap: "5px", display: "flex", }}>
                <button style={styles.arrow}>
                  <KeyboardArrowLeft style={styles.arrow_icon} />
                </button>
                <button style={styles.arrow}>
                  <KeyboardArrowRight style={styles.arrow_icon} />
                </button>
              </Box>
              <Box
                style={{ alignItems: "center", gap: "5px", display: "flex", }}>
                <h1 style={styles.heading}>{configJSON.userFieldsLabel}</h1>
                <img
                  style={styles.infoIconsStyle}
                  src={this.state.isInfoModalOpen ? infoIcon : infoIconOutlined}
                  aria-describedby="infoIcon"
                  onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)} />
              </Box>
            </Box>

            <Box style={{ position: "relative", marginBottom: "32px" }}>
              <Search style={styles.search_icon} />
              <Input
                disableUnderline={true}
                onChange={(e) => this.setInputValue(e.target.value)}
                style={styles.input}
                placeholder={"Search..."}
              />
            </Box>
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box flex={1}>

                <Box style={styles.main_content_card}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "37px",
                    }}
                  >
                    <Box style={styles.cardHead} >
                      <span style={styles.card_heading}>
                        {configJSON.agentFieldsLabel2}
                      </span>
                      <span style={styles.count}>
                        ({this.returAgentFieldsLength()})
                      </span>
                    </Box>
                  </Box>
                  <Box style={styles.card_main_div}>
                  {this.renderAgentFields()}  
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "42px" }}>
                    <Button style={styles.edit_button} onClick={() => this.handleEditToggle('agent')} data-test-id='editagent'>
                      {configJSON.editLabel}
                    </Button>
                  </Box>
                </Box>
                <Box style={styles.main_content_card}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "37px",
                    }}
                  >
                    <Box style={styles.cardHead}>
                      <span style={styles.card_heading}>
                        {configJSON.requesterFieldsLabel2}
                      </span>
                      <span style={styles.count}>
                        ({this.returnRequesterFieldsLength()})
                      </span>
                    </Box>
                  </Box>
                  <Box style={styles.card_main_div}>
                    {this.renderRequesterFields()}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      marginTop: "42px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button data-test-id='editRequester' style={styles.edit_button} onClick={() => this.handleEditToggle('requester')}>{configJSON.editLabel}</Button>
                  </Box>
                </Box>
              </Box>
              <RightSidebar data-test-id="rightSidebar" pathName="UserFields" handleNavigation={this.handleUserFieldsNavigation} />
            </Box>
          </Box>
          <CommonModal
            isOpen={this.state.isInfoModalOpen}
            onClose={() => this.handelInfoModal(false)}
            title={configJSON.userFieldsModalTitle}
            anchorEl={this.state.anchorEl}
            id="infoIcon"
            data={[
              configJSON.userFieldInfoMsg1,
              configJSON.userFieldInfoMsg2
            ]}
          />
          <Dialog
            open={this.state.openEditModal}
            fullWidth={true}
            maxWidth='md'
            PaperProps={{
              style: { padding: '20px' }
            }}
          >
            <Box style={styles.modal_header}>
              <Typography style={styles.modal_heading}>{configJSON.editLabel} {this.state.selectedUser} {configJSON.fieldsLabel}</Typography>
              <Close style={styles.closeIcon} onClick={() => this.handleCloseEditModal(false)} data-test-id='close-modal-icon' />
            </Box>
            <Box style={styles.card_main_div}>
              {this.state.fieldsData.map((field: DynamicField, index: number) => (
                <CustomCard key={`card-${index}`}>
                  <DialogFieldRow>
                    {field.attributes.mandatory === false && <Checkbox
                      checked={field?.attributes?.status}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleRequiredChange(index, e.target.checked)}
                    />}
                    <CustomText>{field.attributes.title}</CustomText>
                  </DialogFieldRow>
                  {(field.attributes.status === true && field.attributes.mandatory === false) &&
                    <RadioGroup
                      row
                      value={field.attributes.optional ? "yes" : "no"}
                      onChange={(e) => this.handleMandatoryChange(index, e.target.value)}
                      style={styles.radioButtonsStyle}
                    >
                      <Typography>{configJSON.radioQuestion}</Typography>
                      <Box>
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </Box>
                    </RadioGroup>
                  }
                </CustomCard>
              ))}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "42px",
                paddingBottom: '1rem'
              }}
            >
              <Button style={styles.cancel_button} onClick={() => this.handleCancelButton()}>{configJSON.cancelBTN}</Button>
              <Button style={{...styles.create_button, textTransform: 'initial'}} onClick={() => this.handleSaveData()}>{configJSON.saveBTN}</Button>
            </Box>
          </Dialog>
        </ThemeProvider>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  heading: {
    fontSize: "30px",
    lineHeight: "40px",
    marginTop: "10px",
    color: "#33354D",
    fontWeight: "bold",
    marginBottom: '0'
  } as const,
  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    height: "28px",
    borderRadius: "100px",
    border: "1px solid #E6DEED",
    backgroundColor: "white",
    cursor: "pointer",
  } as const,
  arrow_icon: {
    width: "20px",
    height: "20px",
    color: "#33354D",
  } as const,
  setting: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    width: "36px",
    height: "36px",
    borderRadius: "4px",
    backgroundColor: "#E6DEED",
  } as const,
  settings_icon: {
    width: "24px",
    height: "24px",
    color: "black",
  } as const,

  table_heading: {
    color: "#33354D",
    fontFamily: 'Manrope',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    marginBottom: '30px'
  } as const,
  card_heading: {
    color: "#33354D",
    fontFamily: 'Manrope',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  } as const,
  count: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "bold",
  } as const,
  main_content_card: {
    flex: "1",
    borderRadius: "8px",
    padding: "16px",
    alignSelf: "flex-start",
    boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)',
    marginBottom: '1rem'
  } as const,
  edit_button: {
    padding: "10px 16px",
    backgroundColor: "#E6DEED",
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: "bolder",
    lineHeight: "16px",
    letterSpacing: "0em",
    borderRadius: "4px",
    textTransform: "initial",
  } as const,
  create_button: {
    color: '#FFF',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    borderRadius: '4px',
    background:'#8760A9',
  },
  cancel_button: {
    fontSize: "16px",
    padding: "10px 16px",
    backgroundColor: "transparent",
    fontFamily: "Manrope",
    color: "#8760A9",
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
  } as const,
  accordians: {
    flexBasis: "30%",
    boxShadow: "0px 4px 8px 0px #8760A921",
    borderRadius: "8px",
    padding: "4px",
  } as const,
  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    height: "40px",
    border: "1px solid #e6deed",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  search_icon: {
    width: "24px",
    height: "24px",
    color: "#33354D",
    position: "absolute",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
  } as const,
  navigation_item: {
    padding: "9px, 20px, 9px, 16px",
    borderRadius: "4px",
  } as const,
  navigation_item_nested: {
    padding: "9px 20px 9px 30px",
    borderRadius: "4px",
  } as const,
  navigation_item_active: {
    backgroundColor: "#8760A9",
    color: "white",
  } as const,
  navigation_link: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "22px",
    letterSpacing: "0em",
    color: "#33354D",
  } as const,
  navigation_link_active: {
    color: "white",
  } as const,
  form_main_Box: {
    padding: '1rem',
  } as const,
  modal_button_main_Box: {
    display: 'flex',
    gap: '1rem',
    float: 'right',
  } as const,
  cardHead: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  card_main_div: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column'
  } as const,
  radioButtonsStyle: {
    marginLeft: '15px',
    flexDirection: 'column'
  } as const,
  infoIconsStyle: {
    color: "#8760A9",
    cursor: 'pointer',
    marginLeft: '10px'
  } as const,
  modal_header: {
    display: 'flex',
    justifyContent: "space-between",
    margin: '1rem 0 2rem'
  } as const,
  closeIcon: {
    cursor: 'pointer',
  } as const,
  navigation_menu_bg: {
    backgroundColor: "#8760A9",
  } as const,
  navigation_menu_color: {
    color: "white",
  } as const,
  moreIconMainStyle: {
    background: '#e6deed',
    borderRadius: '5px'
  } as const,
  moreIconStyle: {
    width: '24px',
    height: '24px',
    color: 'black',
    padding: '5px'
  } as const,
  modal_heading:  {
    fontSize: '11px',
    fontWeight: 700
  }
};
// Customizable Area End
