Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "rolesandpermissions";
exports.labelBodyText = "rolesandpermissions Body";
exports.rolesLabel = 'Roles'
exports.adminRole = 'Admin roles'
exports.agentRole = "Agent roles";
exports.addNewRole = 'Add new role'
exports.createNewRole = "Create new role";
exports.roleNameLabel = "Role Name"
exports.description = "Description"
exports.editRole = "Edit Role";
exports.newAdminRole = "New Admin Role";
exports.editAdminRole = "Edit Admin Role";
exports.newAgentRole = "New Agent Role";
exports.editAgentRole = "Edit Agent Role";
exports.accountAdmin = "Account Admin";
exports.btnExampleTitle = "CLICK ME";
exports.permissionHead = "Perform admin operational admin actions";
exports.permissionOne = "Manage team dashboards";
exports.permissionTwo = "Manage Agents";
exports.permissionThree = "Manage Requester";
exports.permissionFour = "Manage Ticket Templates";
exports.permissionFive = "Manage Change Templates";
exports.permissionSix = "Manage Departments";
exports.permissionSeven = "Create and Edit Service Request Items";
exports.permissionEight = "Manage SLA and OLA Policies";
exports.permissionNine = "Play GOD Mode";
exports.agentPermissions = "Permissions";
exports.ticketsPermissions = "Tickets";
exports.problemPermissions = "Problems";
exports.changesPermissions = "Changes";
exports.releasesPermissions = "Releases";
exports.inventoryPermissions = "Inventory";
exports.solutionsPermissions = "Solutions";
exports.viewTicketsPermission = "View tickets";
exports.ticketReplyPermission = "Send a reply to a ticket";
exports.editNotesPermission = "Edit notes";
exports.editEveryoneNotesPermission = "Edit everyone’s notes";
exports.editOwnNotesPermission = "Edit only their own notes";
exports.deleteConversationPermission = "Delete a conversation";
exports.editTicketPropertiesPermission = "Edit ticket properties";
exports.cancelApprovalsPermission = "Cancel approvals";
exports.editTicketTaskPermission = "Create and edit tasks in ticket";
exports.deleteTicketPermission = "Delete a ticket";
exports.viewProblemsPermission = "View problems";
exports.createProblemsPermission = "Create problems";
exports.editProblemsPermission = "Edit problems";
exports.deleteProblemsPermission = "Delete problems";
exports.createEditTaskPermission = "Create and edit tasks in problems";
exports.viewChagesPermission = "View changes";
exports.createChangesPermission = "Create changes";
exports.editChangesPermission = "Edit changes";
exports.deleteChangesPermission = "Delete changes";
exports.createEditTaskChangesPermission = "Create and edit tasks in changes";
exports.viewReleasesPermission = "View releases";
exports.createReleasesPermission = "Create releases";
exports.editReleasesPermission = "Edit releases";
exports.deleteReleasesPermission = "Delete releases";
exports.createEditTaskReleasesPermission = "Create and edit tasks in releases";
exports.viewAssetsPermission = "View asset";
exports.createEditAssetPermission = "Create and edit asset";
exports.deleteAssetPermission = "Delete asset";
exports.viewSolutionPermission = "View solution";
exports.publishSolutionPermission = "Publish solution";
exports.deleteSolutionPermission = "Delete solution";
exports.saveBTN = "Save";
exports.cancelBTN = "Cancel";
exports.rolesModalTitle = "Understanding roles";
exports.rolesModalSubTitle = "Role Type";
exports.addAdminRole = "Admin role";
exports.addAgentRole = "Agent role";
exports.roleInfoMsg1 = "Roles allow you to create and edit access permissions for agents. You can create new roles, specify what actions agents with these roles can perform within your service desk, and assign the role to agents.";
exports.roleInfoMsg2 = "For example, you can create a role for your Support Co-ordinators, allowing them to update fields and assign tickets, and even add notes internally, but not reply to employees.";
exports.roleInfoMsg3 = "Once you create and save a new role, you will be able to assign it to agents when you create or edit their profile by clicking on the Agents icon under the admin tab.";
exports.roleInfoMsg4 = "Admin roles contain permissions that grant agents the ability to modify service desk configurations.";
exports.roleInfoMsg5 = "Agent roles contain permissions that grant agents the ability to perform everyday activities like responding to tickets, managing changes, and working on projects.";
exports.roleInfoMsg6 = "Learn more about roles and privileges";
exports.closeBtnLabel = 'Close'
exports.rolesApiEndpoint = "bx_block_roles_permissions/roles";
exports.createRoleApiMethod = "POST"
exports.fieldsApiContentType = "application/json";
exports.rolesApiContentType = "application/json";
exports.getRolesApiMethod = 'GET'
exports.updateRolesApiMethod = "PUT"
// Customizable Area End