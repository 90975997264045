import React from "react";
// Customizable Area Start
import CreateEditAdminRoleController, { Props, configJSON } from "./CreateEditAdminRoleController";
import { Box } from "@material-ui/core";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { Toaster } from "react-hot-toast";
import RoleForm from './RoleForm.web';
import { RoleHeader } from "./RoleHeader.web";

// Customizable Area End

export default class CreateEditAdminRole extends CreateEditAdminRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  // Customizable Area End

  render () {
    return (
      // Customizable Area Start
      <React.Fragment>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <Toaster />
        <Box style={styles.container}>
          <RoleHeader heading={ this.state.currentRole ? configJSON.editAdminRole : configJSON.newAdminRole} />
          <RoleForm 
          initialValues={this.state.initialValues} 
          handleSubmit={this.handleSubmit} clearApiError={this.clearApiError} apiErrors={this.state.errors} 
          editorValue={this.state.editorValue} adminPermissions={this.state.adminPermissions} 
          handlePermissions={this.handlePermissions} handleNavigation={this.handleNavigation}
          handleDescription={this.handleDescription}
        />
        </Box>
      </React.Fragment>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "125px",
    fontFamily: "Manrope",
  } as const,

  heading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
  } as const,

}
// Customizable Area End

