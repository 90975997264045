import React from "react";

// Customizable Area Start
import {
    Box,
    Input,
    Paper,
    Typography,
    Card,
    styled,
    Chip,
    Select,
    MenuItem,
    Button,
    Dialog,
    OutlinedInput,
    Grid,
    TextField,
} from "@material-ui/core";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AgentGroupMemberController, {
    Props,
    configJSON,
} from "./AgentGroupMemberController";
import { ExpandMoreRounded, KeyboardArrowRight, MoreVert, RemoveCircleOutline, Search } from "@material-ui/icons";
import { cancelIcon } from "./assets";

// Customizable Area End

export default class AgentGroupMember extends AgentGroupMemberController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    rednderBreadcrumbs = () => {
        return (
            <Box style={webStyle.breadcrumb_wrapper}>
                <span style={webStyle.navigation}>
                    {configJSON.settingLabel} {<KeyboardArrowRight />} {configJSON.userManagementLabel} {<KeyboardArrowRight />} {configJSON.agentGroupsLabel} {<KeyboardArrowRight />} {this.state.groupMemberData.attributes?.name}
                </span>
                <Box style={webStyle.morevert_wrapper}>
                    <span style={webStyle.morevert_span}>
                        <MoreVert style={webStyle.morevert_icon} />
                    </span>
                </Box>
            </Box>
        )
    }

    renderSearchBox = () => {
        return (
            <>
                <Box style={webStyle.arrow_wrapper}>
                    <Box style={webStyle.label_heading} data-testId={'labelHeading'}>
                        <h1 style={webStyle.heading}>{this.state.groupMemberData.attributes?.name}</h1>
                    </Box>
                </Box>

                <Box style={webStyle.search_wrapper}>
                    <Search style={webStyle.search_icon} />
                    <Input
                        disableUnderline={true}
                        style={webStyle.input}
                        value={this.state.searchAgent}
                        onChange={this.handleSearchAgent}
                        placeholder={configJSON.searchPlacholder}
                        data-testId="searchInput"
                    />
                </Box>
            </>
        )
    }

    renderDetailsPaperBox = () => {
        return (
            <>
                <Paper elevation={2} style={webStyle.paperStyle1}>
                    <Box style={webStyle.boxStyle2}>
                        <Typography style={webStyle.fontStyle1}>{configJSON.agentsLabel}</Typography>
                        {this.filterAgents().length > 0 && (
                            <Select
                                style={webStyle.selectBoxStyle}
                                value={this.state.filterOption}
                                onChange={this.handleFilterChange}
                                variant="outlined"
                                data-testId={"change_filter"}
                            >
                                <MenuItem style={this.state.filterOption === 'All' ? webStyle.selectedMenuItem : {}}
                                    value="All">{configJSON.all}</MenuItem>
                                <MenuItem style={this.state.filterOption === 'Member' ? webStyle.selectedMenuItem : {}}
                                    value="Member">{configJSON.member}</MenuItem>
                            </Select>
                        )}
                    </Box>
                    {
                        this.filterAgents().length === 0 ?
                            <Typography style={webStyle.generalFontStyle1}>{configJSON.noagentsadded}</Typography>
                            :
                            this.filterAgents()?.map((agents) => (
                                <MainCard variant="outlined" key={agents.id}>
                                    <Box>
                                        <CardTitle>{agents.full_name}</CardTitle>
                                        <CardParagraph>{agents.email}</CardParagraph>
                                    </Box>
                                    <CustomChip
                                        label={agents.agent_type}
                                        size="medium"
                                        variant="outlined"
                                        style={{ background: agents?.agent_type === "member" ? '#11A64A' : "#6366F1", }}
                                    />
                                </MainCard>
                            ))
                    }
                    <Box style={{ ...webStyle.buttonBox, marginTop: this.filterAgents().length > 0 ? '30px' : '20%' }}>
                        <Button
                            data-testId={'clickModal'}
                            variant="contained"
                            onClick={this.handleOpenDialog}
                            style={webStyle.button}>
                            {this.filterAgents().length > 0 ? configJSON.textAddMembers : configJSON.addAgent}
                        </Button>
                    </Box>
                </Paper>
            </>
        )
    }

    renderEditPaper = () => {
        return (
            <>
                <Paper elevation={2} style={webStyle.paperStyle2}>
                    <Typography style={webStyle.fontStyle1}>{configJSON.textDetails}</Typography>
                    <Box style={webStyle.detailsBox}>
                        <Box style={webStyle.detailData}>
                            <Typography style={webStyle.font1}>{configJSON.businessHourLabel}</Typography>
                            <Typography style={webStyle.font2}>{this.state.currentGroupData.businessHours ? this.state.currentGroupData.businessHours : "--"}</Typography>
                        </Box>
                        <Box style={webStyle.detailData}>
                            <Typography style={webStyle.font1}>{configJSON.categoryLabel}</Typography>
                            <Typography style={webStyle.font2}>{this.state.currentGroupData.selectedCategory ? this.state.currentGroupData.selectedCategory : "--"}</Typography>
                        </Box>
                        <Box style={webStyle.detailData}>
                            <Typography style={webStyle.font1}>{configJSON.textDescription}</Typography>
                            <Typography style={webStyle.font2}>{this.state.currentGroupData.description ? this.state.currentGroupData.description : "--"}</Typography>
                        </Box>
                    </Box>

                    <Box style={{ ...webStyle.buttonBox, marginTop: '30px', }}>
                        <Button
                            variant="contained"
                            onClick={this.openEditModal}
                            data-testId={"open_editmodal"}
                            style={webStyle.button}>
                            {configJSON.textEditDetails}
                        </Button>
                    </Box>
                </Paper>
            </>
        )
    }

    renderAddGroupAgentsDialog = () => {
        const selectedMembers = new Set(this.state.members);
        return (
            <Dialog
                open={this.state.isOpenDialog}
                onClose={this.handleCloseDialog}
                fullWidth={true}
                maxWidth="xs"
                PaperProps={{ style: webStyle.dialogPaperStyle }}
                data-testId={"openModal"}
            >
                <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                    <Typography style={webStyle.fontStyle1}>{configJSON.addAgents}</Typography>
                    <span data-test-id="cancelIcon">
                        <img style={{ cursor: 'pointer' }} onClick={this.handleCloseDialog} src={cancelIcon} alt={configJSON.cancelIconAltText} />
                    </span>
                </Box>

                <Box style={webStyle.dialogBox}>
                    <Box style={webStyle.memberBox}>
                        <Typography>{configJSON.member}</Typography>
                        {this.state.members?.map((member, index) => (
                            <Box style={webStyle.removeIconBox} key={index}>
                                <Select
                                    input={<OutlinedInput />}
                                    IconComponent={ExpandMoreRounded}
                                    name={'template'}
                                    style={webStyle.dropdownStyle}
                                    fullWidth
                                    value={member}
                                    data-testId={`change_member${index}`}
                                    onChange={(event) => this.handleSelectChange(event, index, 'members')}
                                >
                                    {this.state.agentsList.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                            disabled={selectedMembers.has(option.id)}
                                        >
                                            {option.attributes?.full_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <RemoveCircleOutline
                                    data-test-id="remove_icon_click"
                                    style={webStyle.removeIconStyle}
                                    data-testId={'removemember'}
                                    onClick={() => this.handleRemoveMember(index)}
                                />
                            </Box>
                        ))}
                        <Box style={webStyle.buttonBoxAlign}>
                            <Button
                                style={{
                                    ...webStyle.addIconBox,
                                    cursor: this.isAllAgentsAdded() ? 'not-allowed' : 'pointer',
                                    padding: 0
                                }}
                                onClick={this.handleAddMember}
                                disabled={this.isAllAgentsAdded()}
                                data-testId={"addmember"}
                            >
                                <Typography style={{...webStyle.addMemberFont,
                                    color: this.isAllAgentsAdded() ? '#A3A6C2' : '#8760A9'
                                }}>{configJSON.textAddMember}</Typography>
                                <AddCircleOutlineIcon style={{
                                    ...webStyle.removeIconStyle, 
                                    color: this.isAllAgentsAdded() ? '#A3A6C2' : '#8760A9'}} />
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Box style={webStyle.dialogButton}>
                    <Button style={webStyle.cancelButton} onClick={this.handleCloseDialog}>{configJSON.cancelBTN}</Button>
                    <Button variant="contained" style={webStyle.saveButton} data-testId={"handleSubmit"} onClick={this.addAgentsMemberToGroup}>{configJSON.savechanges}</Button>
                </Box>
            </Dialog>
        )
    }

    renderEditDetailsDialog = () => {
        return (
            <>
                <Dialog
                    open={this.state.isEditModal}
                    onClose={this.closeEditModal}
                    fullWidth={true}
                    maxWidth="lg"
                    PaperProps={{ style: webStyle.dialogPaperStyle }}
                    data-testId={"openEdit-dialog"}
                >
                    <Box p={"1rem"}>
                        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                            <Typography style={webStyle.table_heading}>{configJSON.addGroupLabel}</Typography>
                            <span style={webStyle.cancelIconSpan} onClick={this.closeEditModal} data-test-id="cancelIcon">
                                <img src={cancelIcon} alt={configJSON.cancelIconAltText} />
                            </span>
                        </Box>
                        <Typography style={webStyle.generalFontStyle}>{configJSON.generalLabel}</Typography>
                        <Grid container spacing={4} style={{ marginTop: '1%' }}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Box style={webStyle.boxStyle_4}>
                                    <Typography style={webStyle.inputLabel}>{configJSON.groupNameLabel}<span style={webStyle.textColorRed}> *</span></Typography>
                                    <TextField
                                        variant="outlined"
                                        placeholder={configJSON.placeholderGroupName}
                                        value={this.state.groupMemberData.attributes?.name}
                                        onChange={this.handleChangeGroupName}
                                        data-testId={"changeGroupName"}
                                    />
                                    {this.state.groupNameError && <Typography style={webStyle.textColorRed}>{this.state.groupNameError}</Typography>}
                                </Box>

                                <Box style={{ ...webStyle.boxStyle_4, marginTop: '20px' }}>
                                    <Typography style={webStyle.inputLabel}>{configJSON.businessHourLabel}</Typography>
                                    <Select
                                        input={<OutlinedInput />}
                                        IconComponent={ExpandMoreRounded}
                                        style={webStyle.menuItemFont_1}
                                        fullWidth
                                        value={configJSON.selectBusinessHourLabel}
                                    >
                                        <MenuItem disabled value={configJSON.selectBusinessHourLabel} style={webStyle.menuItemFont}>{configJSON.selectBusinessHourLabel}</MenuItem>
                                        <MenuItem value={configJSON.defaultLabel} style={webStyle.menuItemFont}>{configJSON.defaultLabel}</MenuItem>
                                    </Select>
                                </Box>

                                <Box style={{ ...webStyle.boxStyle_4, marginTop: '20px' }}>
                                    <Typography style={webStyle.inputLabel}>{configJSON.categoryLabel}<span style={webStyle.textColorRed}> *</span></Typography>
                                    <Autocomplete
                                        fullWidth
                                        value={this.state.selectedCategory || ''}
                                        onChange={(event: React.ChangeEvent<{}>, value: string | null) => { this.handleChangeCategory(event, value) }}
                                        options={this.state.categories.map(category => category.attributes.name)}
                                        getOptionLabel={(option) => option}
                                        data-testId={"category"}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                placeholder={configJSON.categoryPlaceholder}
                                            />
                                        )}
                                    />
                                    {this.state.categoryError && <Typography data-testId={"function_error"} style={webStyle.textColorRed}>{this.state.categoryError}</Typography>}
                                </Box>

                                <Box style={{ ...webStyle.boxStyle_4, marginTop: '25px' }}>
                                    <Typography style={{ ...webStyle.generalFontStyle }}>{configJSON.groupAutomation}</Typography>
                                    <Typography style={{ ...webStyle.inputLabel, marginTop: '20px' }}>{configJSON.automationTimeLabel}</Typography>
                                    <Autocomplete
                                        fullWidth
                                        value={this.state.selectedBuisnessHours}
                                        onChange={(event: React.ChangeEvent<{}>, value: string | null) => { this.handleChangeBuisnessHours(event, value) }}
                                        options={this.escalationTime}
                                        getOptionLabel={(option) => option}
                                        data-testId={"change_hours"}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Box>

                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Box style={webStyle.boxStyle_4}>
                                    <Typography style={webStyle.inputLabel}>{configJSON.textDescription}</Typography>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        minRows={11}
                                        multiline
                                        data-testId={"description"}
                                        value={this.state.groupMemberData.attributes?.description}
                                        onChange={this.handleChangeDescription}
                                        placeholder={configJSON.placeholderDescription}
                                    />
                                </Box>

                                <Box style={{ ...webStyle.boxStyle_4, marginTop: '14%' }}>
                                    <Typography style={webStyle.inputLabel}>{configJSON.escalationEmailLabel}</Typography>
                                    <Autocomplete
                                        options={this.state.agentsList}
                                        getOptionLabel={(option) => option?.attributes?.full_name}
                                        onChange={this.handleChangeAgentsEmail}
                                        value={this.state.selectedAgentEmail}
                                        data-testId={"change_agents"}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                placeholder={configJSON.selectAgentLabel}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
                                <Box style={{ ...webStyle.buttonBox, marginTop: '30px', }}>
                                    <Button
                                        onClick={() => this.closeEditModal()}
                                        data-testId={'close_modal'} style={webStyle.cancelButton}>
                                        {configJSON.cancelBTN}
                                    </Button>
                                    <Button
                                        onClick={this.updateGroupdata}
                                        data-testId={"textSaveChanges"}
                                        variant="contained"
                                        style={webStyle.submitButton}>
                                        {configJSON.textSaveChanges}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Dialog>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
                <Loader loading={this.state.isLoading} />
                <Toaster toastOptions={{
                    duration: 4000
                }} >
                    {(t) => (
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                        <>
                            {icon}
                            <span style={webStyle.toastMessage}>{message}</span>
                            <Button style={{ minWidth: 20 }} data-testid="toaster-btn" onClick={() => toast.dismiss(t.id)}>
                            X
                            </Button>
                        </>
                        )}
                    </ToastBar>
                    )}
                </Toaster>
                <Box style={webStyle.container}>
                    {this.rednderBreadcrumbs()}
                    {this.renderSearchBox()}
                    <Box style={webStyle.boxStyle1}>
                        {this.renderDetailsPaperBox()}
                        {this.renderEditPaper()}
                    </Box>
                    {this.renderAddGroupAgentsDialog()}
                    {this.renderEditDetailsDialog()}
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainCard = styled(Card)({
    marginBottom: "8px",
    marginTop: '20px',
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "8px"
});

const CustomChip = styled(Chip)({
    color: '#FFF',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    borderRadius: '4px !important',
    border: '0'
})

const CardTitle = styled(Typography)({
    width: "100%",
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
});

const CardParagraph = styled(Typography)({
    color: "#A3A6C2",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontstyle: "normal",
    fontweight: 600,
    lineheight: "normal",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '400px'
});

const webStyle = {
    breadcrumb_wrapper: {
        display: "flex",
        justifyContent: "space-between"
    },

    buttonBoxAlign: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    submitButton: {
        backgroundColor: '#8760A9',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        textTransform: 'initial' as const,
        padding: '5px 25px 5px 25px',
    },

    menuItemFont: {
        fontFamily: 'Manrope',
        color: '#3D405C',
        fontSize: '16px',
        fontWeight: 400
    },
    menuItemFont_1: {
        fontFamily: 'Manrope',
        color: '#A3A6C2',
        fontSize: '16px',
        fontWeight: 400
    },

    textColorRed: {
        color: '#DC2626',
        fontFamily: 'Manrope',
        fontSize: 12,
        fontWeight: 400
    },

    inputLabel: {
        fontFamily: 'Manrope',
        color: '#3D405C',
        fontSize: '12px',
        fontWeight: 500
    },

    boxStyle_4: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '3px'
    },

    generalFontStyle: {
        fontFamily: 'Manrope',
        color: '#33354D',
        fontSize: '18px',
        fontWeight: 700
    },

    generalFontStyle1: {
        fontFamily: 'Manrope',
        color: '#33354D',
        fontSize: '14px',
        fontWeight: 700,
        textAlign: 'center' as const,
        marginTop: '10px'
    },

    toastMessage: {
        fontSize: 12,
        fontWeight: 500,
        fontFamily: "Manrope",
        color: "#33354D"
    },

    cancelIconSpan: {
        height: "30px",
        width: "30px",
        cursor: 'pointer'
    },

    table_heading: {
        color: "#33354D",
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "20px",
        marginBottom: "30px"
    },

    dialogButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '5px',
        alignItems: 'center',
        marginTop: '10%'
    },

    removeIconBox: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
    },

    addIconBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '5px',
        alignItems: 'center',
        marginTop: '10px',
        textTransform: 'initial' as const
    },

    dialogBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: '20px',
        marginTop: '20px'
    },

    removeIconStyle: {
        cursor: 'pointer',
        fontSize: '24px',
        color: '#8760A9'
    },

    memberBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '5px'
    },

    dropdownStyle: {
        borderRadius: '8px'
    },

    button: {
        backgroundColor: '#E6DEED',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 700,
        color: '#33354D',
        textTransform: 'initial' as const,
        padding: '10px 16px 10px 16px',
    },

    saveButton: {
        backgroundColor: '#8760A9',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        textTransform: 'initial' as const,
        padding: '10px 16px 10px 16px',
    },

    cancelButton: {
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 700,
        color: '#8760A9',
        textTransform: 'initial' as const,
        padding: '10px 16px 10px 16px',
    },

    dialogPaperStyle: {
        padding: '25px 40px 25px 40px',
        borderRadius: '8px'
    },

    addMemberFont: {
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 700,
        color: '#8760A9',
        marginRight: '6px'
    },

    detailsBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        marginTop: '25px'
    },

    font1: {
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 600,
        color: '#A3A6C2',
    },

    font2: {
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 700,
        color: '#33354D',
    },

    detailData: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px'
    },

    colum2: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'end',
        gap: '10px'
    },

    fontStyle1: {
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: 700,
        color: '#33354D'
    },

    buttonBox: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'flex-end' as const,
        marginRight: "8px"
    },

    paperStyle1: {
        width: '70%',
        padding: '15px',
        marginTop: '10px'
    },

    paperStyle2: {
        width: '30%',
        padding: '15px',
        marginTop: '10px',
        height: 'max-content'
    },

    selectBoxStyle: {
        width: '140px',
        borderRadius: '8px',
        height: '40px',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,
        color: '#8760A9'
    },

    selectedMenuItem: {
        backgroundColor: '#8760A9',
        borderRadius: '8px',
        padding: "12px 16px 12px 16px",
        color: "#FFFFFF",
        fontFamily: "Manrope",
        fontSize: '16px'
    },

    boxStyle2: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '5px',
        marginRight: '8px'
    },

    morevert_wrapper: {
        background: "#e6deed",
        borderRadius: "5px"
    },

    morevert_icon: {
        width: "24px",
        height: "24px",
        color: "black",
        padding: "5px"
    },

    boxStyle1: {
        width: '100%',
        display: 'flex',
        gap: '20px'
    },

    morevert_span: {
        width: "36px",
        height: "36px"
    },

    navigation: {
        fontFamily: "Manrope",
        fontSize: "14px",
        lineHeight: "19px",
        display: "flex"
    },

    container: {
        height: "100%",
        margin: "15px 15px 15px 300px",
        paddingBottom: "25px",
        fontFamily: "Manrope"
    },

    arrow_wrapper: {
        marginBottom: "50px",
        marginTop: "60px",
    },

    arrow_btn_wrapper: {
        gap: "5px",
        display: "flex",
    },

    arrow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "28px",
        height: "28px",
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: "100px",
        border: "1px solid #E6DEED",
    },

    arrow_icon: {
        width: "20px",
        height: "20px",
        color: "#33354D",
    },

    label_heading: {
        alignItems: "center",
        display: "flex",
        gap: "5px"
    },

    info_color: {
        color: "#8760A9"
    },

    heading: {
        color: "#33354D",
        fontSize: "30px",
        margin: "10px 0 0",
        lineHeight: "40px",
    },

    search_wrapper: {
        position: "relative" as const,
        marginBottom: "32px"
    },

    search_icon: {
        width: "24px",
        color: "#33354D",
        height: "24px",
        position: "absolute" as const,
        transform: "translateY(-50%)",
        top: "50%",
        left: "10px"
    },

    input: {
        fontFamily: "Manrope",
        width: "100%",
        border: "1px solid #e6deed",
        height: "40px",
        padding: "10px 10px 10px 40px",
        fontSize: "16px",
        borderRadius: "10px"
    },
};
// Customizable Area End
