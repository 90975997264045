import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete';

export interface CategoriesResponseJson {
  data: Array<CategoryData>;
}

export interface CategoryData {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
  }
}

export interface AgentGroupsListData {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    business_hours: string;
    business_function: string;
    automation_time: string;
    escalation_email: string;
    user_type: string;
    agents: {
      id: number;
      full_name: string;
      email: string;
    }[]
  }
}

interface ResponseJson {
  data: Array<AgentGroupsListData>;
}

export interface AgentList {
  attributes: {
    full_name: string;
    email: string;
  }
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface NavigationItem {
  title: string;
  list?: Array<NavigationItem>;
  href?: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  searchGroup: string;
  AgentGroupsList: Array<AgentGroupsListData>;
  page: number;
  rowsPerPage: number;
  openListIndex: number;
  showCreateModal: boolean;
  agentsList: AgentList[];
  groupName: string;
  groupNameError: string;
  category: string;
  categoryError: string;
  selectedBuisnessHours: string;
  description: string;
  selectedAgentEmail: AgentList | null;
  showMembers: boolean;
  groupId: string | null;
  isInfoModalOpen: boolean;
  anchorEl: any;
  categories: Array<CategoryData>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AgentGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllAgentGroupsCallId: string = "";
  getAllAgentsApiCallId: string = "";
  pathname = "/user-management/agent-groups";
  escalationTime: Array<string> = ["15 Minutes", "30 Minutes", "1 Hour", "2 Hours", "4 Hours", "8 Hours", "12 Hours", "1 Day", "2 Days", "3 Days"];
  token: string = "";
  updateAddagentsAPICallId: string = "";
  getSearchGroupAPICallId: string = "";
  getAllCategoriesCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page: 0,
      isLoading: false,
      searchGroup: "",
      AgentGroupsList: [],
      rowsPerPage: 5,
      openListIndex: -1,
      showCreateModal: false,
      selectedBuisnessHours: '15 minutes',
      agentsList: [],
      groupName: '',
      groupNameError: '',
      category: '',
      categoryError: '',
      description: '',
      selectedAgentEmail: null,
      showMembers: false,
      groupId: null,
      isInfoModalOpen: false,
      anchorEl: null,
      categories: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    switch (apiRequestCallId) {
      case this.getAllAgentsApiCallId:
        this.handleAllAgentsApiDataResponse(responseJson);
        break;
      case this.getAllAgentGroupsCallId:
        this.handleAllAgentGroupApiDataResponse(responseJson);
        break;
      case this.updateAddagentsAPICallId:
        this.handleCreateAgentSucesCallBack(responseJson);
        break;
      case this.getSearchGroupAPICallId:
        this.handleSearchGroupAPIResponse(responseJson);
        break;
      case this.getAllCategoriesCallId:
        this.handleAllCategoriesApiResponse(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken');
    this.getAllAgentGroups();
    this.getAllAgents();
    this.getAllCategories();
  }

  // Web Events
  onUnload = () => {
    this.props.navigation.navigate("AgentGroup")
  }

  handleChangeGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const groupname = event.target.value;
    const isNameEmpty = groupname.trim() === '';
    this.setState({
      groupName: groupname,
      groupNameError: isNameEmpty ? configJSON.groupNameRequire : ''
    });
  }

  handleChangeCategory = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    if (!value) {
      this.setState({ categoryError: configJSON.categoryRequire });
    } else {
      this.setState({ category: value, categoryError: '' });
    }
  };

  handleChangeBuisnessHours = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    if (value !== null) {
      this.setState({ selectedBuisnessHours: value });
    }
  };

  handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: event.target.value })
  }

  handleChangeAgentsEmail = (
    event: React.ChangeEvent<{}>,
    value: AgentList | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<AgentList> | undefined
  ) => {
    if (value !== null) {
      this.setState({ selectedAgentEmail: value });
    } else {
      this.setState({ selectedAgentEmail: null });
    }
  };

  handleClickGroup = async (index: string) => {
    await setStorageData("groupId", index)
    this.setState({ showMembers: true })
    this.props.navigation.navigate('AgentGroupMember', { id: index });
  }

  handleSearchGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchGroup = event.target.value;
    this.setState({ searchGroup }, () => {
      if (searchGroup.trim() === '') {
        this.getAllAgentGroups();
      } else {
        this.getSearchGroupAPI();
      }
    });
  };

  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
        isInfoModalOpen: true,
        anchorEl: event?.currentTarget,
    });
  };


  handelAgentGroupInfoModal = (value: boolean) => {
    this.setState({ isInfoModalOpen: value });
  }

  handleAgentGroupNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  // API Integration
  getAllCategories = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCategoriesEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllAgentGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAgentGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAgentGroupsApiEndPoint}?user_type=agent`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllAgents = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAgentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAgentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addAgentsToGroup = async () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ isLoading: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const httpBody = {
      group: {
        automation_time: this.state.selectedBuisnessHours,
        category_id: this.state.categories.find(cat => cat.attributes.name === this.state.category)?.id,
        business_hours: "",
        description: this.state.description,
        escalation_email: this.state.selectedAgentEmail?.attributes.email,
        name: this.state.groupName,
        user_type: "agent"
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAgentGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.updateAddagentsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchGroupAPI = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchGroupAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchGroupAPIendPoint}?search=${this.state.searchGroup}&user_type=agent`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validateForm = () => {
    let isValid:boolean = true;
    if (this.state.groupName.trim() === '') {
      this.setState({ groupNameError: configJSON.groupNameRequire });
      isValid = false;
    }

    if (!this.state.category) {
      this.setState({ categoryError: configJSON.categoryRequire });
      isValid = false;
    }

    return isValid;
  }

  openAccordian = (index: number) => {
    this.setState((prevState) => ({ openListIndex: prevState.openListIndex === index ? -1 : index }));
  };
  // API Response

  handleAllAgentGroupApiDataResponse = (responseJson: ResponseJson) => {
    if (responseJson?.data) {
      this.setState({
        AgentGroupsList: responseJson.data
      })
    }
  };

  handleAllAgentsApiDataResponse = (responseJson: { data: AgentList[] }) => {
    if (responseJson?.data) {
      const agentSData = responseJson.data.map((agents) => ({
        attributes: {
          full_name: agents.attributes.full_name || '',
          email: agents.attributes.email || ''
        }
      }));
      this.setState({
        agentsList: agentSData
      })
    }
  };

  handleCreateAgentSucesCallBack(responseJson: { data: { attributes: { name: string } }, errors: any }) {
    if (responseJson?.data) {
      this.handleCreateModalClose()
      this.setState({ isLoading: false })
      this.getAllAgentGroups();
      toast.success(responseJson.data.attributes.name);
    } if (responseJson.errors) {
      responseJson.errors.forEach((error:string) => {
          if (error.includes("Name")) {
              this.setState({ groupNameError: error });
          }
          if (error.includes("Category")) {
              this.setState({ categoryError: error });
          }
      });
  }

  }

  handleSearchGroupAPIResponse = (responseJson: ResponseJson) => {
    if (responseJson?.data) {
      this.setState({
        AgentGroupsList: responseJson.data
      })
    }
  }

  handleAllCategoriesApiResponse = (responseJson: CategoriesResponseJson) => {
    if (responseJson.data) {
      this.setState({ categories: responseJson.data });
    }
  }

  handleCreateModalOpen = () => {
    this.setState({ showCreateModal: true });
  };

  resetFormData() {
    this.setState({ groupName: '', groupNameError: '', category: '', categoryError: '',
      description: '', selectedAgentEmail: null, selectedBuisnessHours: '15 minutes' });
  }

  handleCreateModalClose = () => {
    this.setState({ showCreateModal: false });
    this.resetFormData();
  };
  // Customizable Area End
}
