import React from "react";
// Customizable Area Start
import RequesterGroupsMemberController, { configJSON, Props, RequesterData } from "./RequesterGroupsMemberController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { Box, Button, Card, Dialog, Grid, Input, MenuItem, OutlinedInput, Paper, Select, styled, TextField, Typography } from "@material-ui/core";
import { ExpandMoreRounded, KeyboardArrowRight, MoreVert, RemoveCircleOutline, Search } from "@material-ui/icons";
import { cancelIcon } from "./assets";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// Customizable Area End

export default class RequesterGroupsMember extends RequesterGroupsMemberController {
  constructor (props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  rednderBreadcrumbs = () => {
    return (
        <Box style={styles.breadcrumb_wrapper}>
            <span style={styles.navigation}>
                {configJSON.settingLabel} {<KeyboardArrowRight />} {configJSON.userManagementLabel} {<KeyboardArrowRight />} {configJSON.requesterGroupsLabel} {<KeyboardArrowRight />} {this.state.currentRequesterGroup?.attributes?.name}
            </span>
            <Box style={styles.morevert_wrapper}>
                <span style={styles.morevert_span}>
                    <MoreVert style={styles.morevert_icon} />
                </span>
            </Box>
        </Box>
    )
}

renderSearchBar = () => {
  return (
    <Box style={styles.search_wrapper}>
      <Search style={styles.search_icon} />
      <Input
        disableUnderline={true}
        value={this.state.searchRequester}
        onChange={this.handleSearchGroup}
        style={styles.input}
        placeholder={configJSON.searchPlacholder}
        data-testid={"search_group"}
      />
    </Box>
  )
}

renderAddRequesterInGroupDialog = () => {
  const selectedMembers = new Set(this.state.members);
  return (
      <Dialog
          open={this.state.openAddRequesterModal}
          onClose={this.handleCloseMembersDialog}
          fullWidth={true}
          maxWidth="xs"
          PaperProps={{ style: styles.dialogPaperStyle }}
          data-testId={"openModal"}
      >
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
              <Typography style={styles.fontStyle1}>{configJSON.addMembers}</Typography>
              <span data-test-id="cancelIcon">
                  <img style={{ cursor: 'pointer' }} onClick={this.handleCloseMembersDialog} src={cancelIcon} alt={configJSON.cancelIconAltText} />
              </span>
          </Box>

          <Box style={styles.dialogBox}>
              <Box style={styles.memberBox}>
                  <Typography style={styles.memberText}>{configJSON.member}</Typography>
                  {this.state.members.map((member, index) => (
                      <Box style={styles.removeIconBox} key={index}>
                          <Select
                              input={<OutlinedInput />}
                              IconComponent={ExpandMoreRounded}
                              name={'template'}
                              style={styles.dropdownStyle}
                              fullWidth
                              value={member}
                              data-testId={`change_member${index}`}
                              onChange={(event) => this.handleSelectChange(event, index)}
                          >
                              {this.state.requesterList.map((option) => (
                                  <MenuItem
                                      key={option.id}
                                      value={option.id}
                                      disabled={selectedMembers.has(option.id as string)}
                                  >
                                      {option.attributes?.full_name}
                                  </MenuItem>
                              ))}
                          </Select>
                          <RemoveCircleOutline
                              style={styles.removeIconStyle}
                              data-testid='removemember'
                              onClick={() => this.handleRemoveMember(index)}
                          />
                      </Box>
                  ))}
                  <Box style={styles.buttonBoxAlign}>
                      <Button
                          style={{
                              ...styles.addNewMemberBtn,
                              cursor: this.isAllRequestersAdded() ? 'not-allowed' : 'pointer',
                          }}
                          onClick={this.handleAddMember}
                          disabled={this.isAllRequestersAdded()}
                          data-testId={"addmember"}
                      >
                          <Typography 
                          style={{ ...styles.addMemberText, 
                          color: this.isAllRequestersAdded() ? '#A3A6C2' : '#8760A9' }}>{configJSON.textAddMember}</Typography>
                          <AddCircleOutlineIcon style={{ ...styles.addIconStyle,
                            color: this.isAllRequestersAdded() ? '#A3A6C2' : '#8760A9'
                          }} />
                      </Button>
                  </Box>
              </Box>


          </Box>

          <Box style={styles.dialogButton}>
              <Button style={styles.cancelButton} data-testid="cancelBtn" onClick={this.handleCloseMembersDialog}>{configJSON.cancelBTN}</Button>
              <Button variant="contained" style={styles.saveButton} data-testid="handleSubmit" onClick={this.addRequesterToGroup}>{configJSON.textSaveChanges}</Button>
          </Box>
      </Dialog>
  )
}

renderEditDetailsDialog = () => {
  return (
      <>
        <Dialog
          open={this.state.openEditModal}
          onClose={this.closeEditGroupModal}
          fullWidth={true}
          maxWidth="sm"
          PaperProps={{ style: styles.dialogPaperStyle }}
          data-testid={"openEdit-dialog"}
        >
          <Box p={"1rem"}>
              <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                  <Typography style={styles.table_heading}>{configJSON.textEditDetails}</Typography>
                  <span style={styles.cancelIconSpan} onClick={this.closeEditGroupModal} data-testid="cancelIcon">
                      <img src={cancelIcon} alt={configJSON.cancelIconAltText} />
                  </span>
              </Box>
              <Typography style={styles.generalFontStyle}>{configJSON.generalLabel}</Typography>
              <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box style={styles.boxStyle_4}>
                          <Typography style={styles.inputLabel}>{configJSON.groupNameLabel}<span style={styles.textColorRed}> *</span></Typography>
                          <TextField
                              variant="outlined"
                              placeholder={configJSON.placeholderGroupName}
                              value={this.state.groupName}
                              onChange={this.handleChangeGroupName}
                              data-testid={"changeGroupName"}
                          />
                          {this.state.groupNameError && <Typography data-testid="groupNameError" style={styles.textColorRed}>{this.state.groupNameError}</Typography>}
                      </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box style={styles.boxStyle_4}>
                          <Typography style={styles.inputLabel}>{configJSON.textDescription}</Typography>
                          <TextField
                              variant="outlined"
                              fullWidth
                              minRows={11}
                              multiline
                              placeholder={configJSON.placeholderTextDescription}
                              data-testid={"description"}
                              value={this.state.description}
                              onChange={this.handleChangeDescription}
                          />
                      </Box>
                  </Grid>

                  <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
                      <Box style={{ ...styles.buttonBox, marginTop: '30px', }}>
                          <Button
                              onClick={() => this.closeEditGroupModal()}
                              data-testid={'close_modal'} style={styles.cancelButton}>
                              {configJSON.cancelBTN}
                          </Button>
                          <Button
                              onClick={this.updateRequestGroup}
                              data-testid={"saveChangeBtn"}
                              variant="contained"
                              style={styles.submitButton}>
                              {configJSON.textSaveChanges}
                          </Button>
                      </Box>
                  </Grid>
              </Grid>
          </Box>
        </Dialog>
      </>
  )
}
  // Customizable Area End

  render(): React.ReactNode {
    // Customizable Area Start
    const { currentRequesterGroup } = this.state;
    return (
      <React.Fragment>
      <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
      <Loader loading={this.state.isLoading} />
      <Toaster toastOptions={{
          duration: 4000
      }} >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                <span style={styles.toastMessage}>{message}</span>
                <Button style={{ minWidth: 20 }} data-testid="toaster-btn" onClick={() => toast.dismiss(t.id)}>
                  X
                </Button>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <Box style={styles.container}>
          {this.rednderBreadcrumbs()}
          <Box style={styles.headingMain}>
            <h1 style={styles.heading}>{currentRequesterGroup?.attributes?.name}</h1>
          </Box>
          {this.renderSearchBar()}
          <Box style={styles.boxStyle1}>
            <Paper elevation={2} style={styles.paperStyle1}>
              <Box style={styles.boxStyle2}>
                <Typography style={styles.fontStyle1}>{configJSON.membersLabel}</Typography>
              </Box>
              {currentRequesterGroup && currentRequesterGroup.attributes.agents.length > 0 ? 
                currentRequesterGroup.attributes.agents.map((requester: RequesterData) => (
                  <MainCard variant="outlined" key={requester.id}>
                    <Box>
                      <CardTitle>{requester.full_name}</CardTitle>
                      <CardParagraph>{requester.email}</CardParagraph>
                    </Box>
                  </MainCard>
                ))
              :
              <Typography style={{ ...styles.generalFontStyle1, marginTop: "15%" }}>{configJSON.noMemberAddedMsg}</Typography>}
              <Box style={{ ...styles.buttonBox, marginTop: '15%' }}>
                <Button
                    data-testid='addMemberModal'
                    variant="contained"
                    onClick={() => this.addRequesterManually()}
                    style={styles.button}>
                    {configJSON.textAddMembers}
                </Button>
              </Box>
            </Paper>
            <Paper elevation={2} style={styles.paperStyle2}>
                <Typography style={styles.fontStyle1}>{configJSON.textDetails}</Typography>
                <Box style={styles.detailsBox}>
                  <Box style={styles.colum1}>
                    <Typography style={styles.font1}>{configJSON.textDescription}</Typography>
                    <Typography style={styles.font2}>{currentRequesterGroup?.attributes?.description}</Typography>
                  </Box>
                </Box>

                <Box style={{ ...styles.buttonBox, marginTop: '30px' }}>
                  <Button
                      variant="contained"
                      onClick={() => this.openRequesterGroupEditModal()}
                      data-testid={"open_editmodal"}
                      style={{ ...styles.button, marginTop: "85%" }}>
                      {configJSON.textEditDetails}
                  </Button>
                </Box>
            </Paper>
            {this.state.openEditModal && this.renderEditDetailsDialog()}
            {this.state.openAddRequesterModal && this.renderAddRequesterInGroupDialog()}
          </Box>
      </Box>
  </React.Fragment>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

const MainCard = styled(Card)({
  marginBottom: "8px",
  marginTop: '20px',
  padding: "12px",
  display: "flex",
});

const CardTitle = styled(Typography)({
  width: "100%",
  color: "#33354D",
  fontFamily: "Manrope",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const CardParagraph = styled(Typography)({
  color: "#A3A6C2",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontstyle: "normal",
  fontweight: 600,
  lineheight: "normal",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: '400px'
});

const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope"
  },

  breadcrumb_wrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  
  morevert_wrapper: {
    background: "#e6deed",
    borderRadius: "5px"
  },

  morevert_icon: {
    width: "24px",
    height: "24px",
    color: "black",
    padding: "5px"
  },

  toastMessage: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Manrope",
    color: "#33354D"
  },

  boxStyle1: {
    width: '100%',
    display: 'flex',
    gap: '20px'
  },

  colum1: {
    display: 'flex',
    gap: '10px',
    flexGrow: 1,
    justifyContent: 'space-between'
  },

  morevert_span: {
      width: "36px",
      height: "36px"
  },

  detailsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '25px'
  },

  font1: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 600,
    color: '#A3A6C2',
  },

  font2: {
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 700,
    color: '#33354D',
  },

  boxStyle_4: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '3px'
  },

  cancelIconSpan: {
    height: "30px",
    width: "30px",
    cursor: 'pointer'
  },

  table_heading: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    marginBottom: "30px"
},

  headingMain: {
    marginTop: "60px",
    marginBottom: "50px"
  },

  heading: {
    fontFamily: "Manrope",
    fontSize: 30,
    fontWeight: 600,
    lineHeight: "40.98px",
    color: "#33354D"
  },

  boxStyle2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
    marginRight: '8px'
  },

  fontStyle1: {
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontWeight: 700,
    color: '#33354D'
  },

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex"
  },
  generalFontStyle: {
    fontFamily: 'Manrope',
    color: '#33354D',
    fontSize: '18px',
    fontWeight: 700
  },

  textColorRed: {
    color: "#F22A2A"
  },

  generalFontStyle1: {
    fontFamily: 'Manrope',
    color: '#33354D',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center' as const,
    marginTop: '10px'
  },

  paperStyle1: {
    width: '70%',
    padding: '15px',
    marginTop: '10px',
    height: "100%"
  },

  paperStyle2: {
    width: '30%',
    padding: '15px',
    marginTop: '10px',
    minHeight: '350px'
  },

  search_wrapper: {
    position: "relative" as const,
    marginBottom: "32px"
  },

  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute" as const,
    transform: "translateY(-50%)",
    top: "50%",
    left: "10px"
  },

  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px"
  },

  buttonBox: {
    display: 'flex',
    alignItems: 'center' as const,
    justifyContent: 'flex-end' as const,
    marginRight: "8px"
  },

  button: {
    backgroundColor: '#E6DEED',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#33354D',
    textTransform: 'initial' as const,
    padding: '10px 16px 10px 16px',
  },

  dialogPaperStyle: {
    padding: '25px',
    borderRadius: '8px'
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 25px 5px 25px',
  },

  cancelButton: {
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#8760A9',
    textTransform: 'initial' as const,
    padding: '10px 16px 10px 16px',
  },

  dialogBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '20px',
    marginTop: '20px'
  },

  dialogButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '5px',
    alignItems: 'center',
    marginTop: '10%'
  },

  saveButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'none',
    padding: '10px 16px 10px 16px',
},

  removeIconStyle: {
    cursor: 'pointer',
    fontSize: '24px',
    color: '#8760A9'
  },

  addIconStyle: {
    cursor: 'pointer',
    fontSize: '24px',
    color: '#8760A9',
    marginRight: -8
  },

  memberBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '10px'
  },

  memberText: {
    fontFamily: "Manrope",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16.39px",
    textAlign: "left",
    color: "#3D405C"
  },
  
  dropdownStyle: {
    borderRadius: '8px'
  },

  addNewMemberBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    alignItems: 'center',
    marginTop: '10px',
    textTransform: 'none',
  },

  addMemberText: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: 14,
    marginRight: 10
  },

  buttonBoxAlign: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  removeIconBox: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
}
// Customizable Area End